import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { SalesRepGroupModel } from '@ov-suite/models-order';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: SalesRepGroupModel,
      createTitle: 'Sales Rep Group',
      route: ['sales-rep-group'],
      tableTitle: 'Sales Rep Groups',
    }),
  ],
})
export class SalesRepGroupModule {}
