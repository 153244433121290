import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerDetailsBarComponent } from './customer-details-bar.component';
import { UiModule } from '@ov-suite/ui';
import {LeafletModule} from "@asymmetrik/ngx-leaflet";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UiModule,
        ReactiveFormsModule,
        NgbDropdownModule,
        NgbTooltipModule,
        LeafletModule
    ],
  declarations: [CustomerDetailsBarComponent],
  providers: [],
  exports: [CustomerDetailsBarComponent],
})
export class CustomerDetailsBarModule {}
