import { Routes } from '@angular/router';
import { SalesConstraintListComponent } from './list/sales-constraint-list.component';
import { SalesConstraintAddOrEditComponent } from './add-or-edit/sales-constraint-add-or-edit.component';

export const SalesConstraintRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: SalesConstraintListComponent,
      },
      {
        path: 'add',
        component: SalesConstraintAddOrEditComponent,
      },
      {
        path: 'edit',
        component: SalesConstraintAddOrEditComponent ,
      },
    ],
  },
];
