<h1 mat-dialog-title>Add Constraint</h1>
<form class="example-form" [formGroup]="formGroup" (submit)="submit()">
  <div mat-dialog-content>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Amount</mat-label>
      <input type="number"
             placeholder="Amount"
             aria-label="Amount"
             matInput
             required
             [min]="1"
             formControlName="value">
    </mat-form-field>
    <mat-checkbox
      labelPosition="before"
      formControlName="allowMultiples"
      [checked]="formGroup.value.allowMultiples"
      (click)="formGroup.value.allowMultiples = !formGroup.value.allowMultiples"
    >
      Allow Multiples
    </mat-checkbox>
  </div>

  <div mat-dialog-actions class="float-right">
    <button mat-stroked-button color="warn" type="button" (click)="close()">Cancel</button>
    <button mat-button color="primary" type="submit" [disabled]="!formGroup.valid">Add</button>
  </div>
</form>
