<div class="main-content">
  <div class="card">
    <div class="float-left width-100">
    <div>
      <label>
          <span class="add-edit-header-main" (click)="back()" *ngIf="order">
          <i aria-hidden="true" class="arrow-left-dark"></i> &nbsp; {{getTitle()}}
        </span>
      </label>
    </div>
    <div class="buttons">
      <button class="btn btn-outline-default float-left" (click)="close()">
      <span class="float-left">
          Cancel
        </span>
        <span class="float-right">
          <i aria-hidden="true" class="x-dark"></i>
        </span>
      </button>
      <button class="btn btn-ovsuite-primary float-right" (click)="split()">
        <span class="float-left">
          Split
        </span>
        <span class="float-right">
          <i aria-hidden="true" class="check-1"></i>
        </span>
      </button>
    </div>
  </div>
    <span *ngIf="!this.order" class="spinner-border spinner-border-sm accordion-spinner" role="status" aria-hidden="true"></span>

  <table *ngIf="order?.orderItems?.length>0">
    <tr>
      <th colspan="5" class="accordion-table-header-row w-50 split-order-header">Current order</th>
      <th colspan="3" class="accordion-table-header-row w-50 split-order-header">Split order</th>
    </tr>
    <tr>
      <th class="accordion-table-header-row" [ngClass]="i > 4 ? 'split-order' : ''" *ngFor="let header of headers; let i = index">{{header}}</th>
    </tr>
    <tr *ngFor="let orderItem of order.orderItems; let i = index">
      <td class="accordion-table-content-row" >{{orderItem.productSku.name}}</td>
      <td class="accordion-table-content-row" >{{orderItem.productSku.sku}}</td>
      <td class="accordion-table-content-row" >
        <ov-suite-increment-picker
          [sku]="orderItem.productSku"
          [currentValue]="orderItem.quantity"
          (changeValue)="quantityChange(splitOrderItems[i], orderItem, $event, false)"
          [overrideIncrementDecrement]="true"
          [incrementDisabled]="splitOrderItems[i].quantity === 0"
          [decrementDisabled]="orderItem.quantity === 0"
        ></ov-suite-increment-picker>
      </td>
      <td class=" accordion-table-content-row" >R{{orderItem.unitPriceExcl.toFixed(2)}}</td>
      <td class=" accordion-table-content-row total" >R{{(orderItem.unitPriceExcl * orderItem.quantity).toFixed(2)}}</td>
      <td class="accordion-table-content-row split-order" >
        <ov-suite-increment-picker
          [sku]="splitOrderItems[i]?.productSku"
          [currentValue]="splitOrderItems[i]?.quantity"
          (changeValue)="quantityChange(splitOrderItems[i], orderItem, $event, true)"
          [overrideIncrementDecrement]="true"
          [incrementDisabled]="orderItem.quantity === 0"
          [decrementDisabled]="splitOrderItems[i].quantity === 0"
        ></ov-suite-increment-picker>
      </td>
      <td class=" accordion-table-content-row split-order" >R{{splitOrderItems[i]?.unitPriceExcl.toFixed(2)}}</td>
      <td class=" accordion-table-content-row total split-order" >R{{(splitOrderItems[i]?.unitPriceExcl * splitOrderItems[i]?.quantity).toFixed(2)}}</td>
    </tr>
    <tr>
      <td class="accordion-table-header-row split-order-header">Total</td>
      <td colspan="3" class="accordion-table-header-row split-order-header"></td>
      <td class="accordion-table-header-row split-order-header">R{{getTotalPriceExcl(order.orderItems).toFixed(2)}}</td>
      <td colspan="2" class="accordion-table-header-row split-order-header"></td>
      <td class="accordion-table-header-row split-order-header">R{{getTotalPriceExcl(splitOrderItems).toFixed(2)}}</td>
    </tr>
  </table>
  </div>
</div>
