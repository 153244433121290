import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { SalesRepModel } from '@ov-suite/models-order';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: SalesRepModel,
      createTitle: 'Sales Rep',
      route: ['sales-rep'],
      tableTitle: 'Sales Reps',
    }),
  ],
})
export class SalesRepModule {}
