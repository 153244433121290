<div class="main-content">
  <div class="main-container px-4 py-3">
    <span id="backButton" (click)="navigateBack()">
        </span>
    <div class="return-header">Allocate to Sales Rep</div>

    <ov-suite-predictive-text-dropdown
      [id]="'salesRep'"
      [title]="'Sales Rep'"
      [formClass]="salesRep"
      [limit]="10"
      [displayFunction]="displayFunctionSalesReps"
      [dropdownDisplayKey]="'user.firstName'"
      [searchKeys]="['user.firstName']"
      [query]="{'customers.id' : [order.customer.id]}"
      [tooltip]="'Sales Rep'"
      [initial]="salesRep"
      [(ngModel)]="order.salesRep"
    ></ov-suite-predictive-text-dropdown>
    <br>

    <div>
      <button class="btn btn-outline-default float-left" (click)="close()">
        <span class="float-left">
          Cancel
        </span>
        <span class="float-right">
          <i aria-hidden="true" class="x-dark"></i>
        </span>
      </button>
      <button class="btn btn-ovsuite-primary float-right" (click)="allocate()">
        <span class="float-left">
          Allocate
        </span>
        <span class="float-right">
          <i aria-hidden="true" class="check-1"></i>
        </span>
      </button>
    </div>
  </div>
</div>
