import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { OrderModel, SalesRepModel } from '@ov-suite/models-order';
import swal from 'sweetalert2';


@Component({
  selector: 'ov-suite-allocate-sales-rep-modal',
  templateUrl: './allocate-sales-rep-modal.component.html',
  styleUrls: ['./allocate-sales-rep-modal.component.scss'],
})
export class AllocateSalesRepModalComponent {

  constructor(public ovAutoService: OvAutoService,
              protected modalService: NgbModal,) {
  }

  @Input() order: OrderModel;
  salesReps: SalesRepModel[];
  salesRep = SalesRepModel;
  dropdownData: Record<string, unknown>;
  displayFunctionSalesReps = item => `${item.user.firstName} ${item.user.lastName}`;

  allocate(){
    let order = new OrderModel();
    order.salesRepId =  this.order.salesRep.id;
    order.id = this.order.id;
    const keys = ['salesRepId'];
    this.ovAutoService.update({ entity: OrderModel, item: order, keys }).then(() => {
      swal.fire({
        title: 'Sales Rep Allocated.',
        text: this.order.salesRep.user.email + ' Allocated to Order: ' + this.order.id,
        showConfirmButton: false,
        type: 'success',
      });
      this.modalService.dismissAll();
    });
  }

  navigateBack(){
    window.history.back();
  }

  close = () => {
    this.modalService.dismissAll();
  };
}
