import { Component, Inject, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { OrderModel, OrderItemModel, Quantities } from '@ov-suite/models-order';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductSkuModel } from '@ov-suite/models-admin';
import { getCreate } from '@ov-suite/graphql-helpers';
import swal from 'sweetalert2';

@Component({
  selector: 'ov-suite-split-details-list',
  templateUrl: './split-details-list.component.html',
  styleUrls: ['./split-details-list.component.scss'],
})
export class SplitDetailsListComponent implements OnInit {
  formClass = OrderItemModel;

  keys = [
    'id',
    'orderDate',
    'orderCode',
    'customer',
    'priority',
    'fulfilmentDate',
    'deliveryAddress',
    'specialInstructions',
    'customer.id',
    'customer.address',
    'customer.customerCode',
    'customer.description',
    'customer.map',
    'customer.mobileNumber',
    'customer.name',
    'customer.primaryEmail',
    'customer.specialInstructions',
    'customer.creditLimit',
    'orderItems',
    'orderItems.id',
    'orderItems.orderId',
    'orderItems.productSku',
    'orderItems.quantity',
    'orderItems.unitPriceExcl',
    'orderItems.unitPriceIncl',
    'productSku.id',
    'productSku.name',
    'productSku.salesUnit',
    'productSku.storageUnit',
    'productSku.products',
    'productSku.threshold',
    'productSku.productSkuConfigurations',
    'productSku.category',
    'category',
    'category.name',
    'category.parent',
    'productSku.category.parent.id',
    'productSku.quantityFree',
    'productSku.taxRate',
    'productSku.prices',
    'productSku.salesLimit',
    'productSkuConfigurations.id',
    'productSkuConfigurations.name',
    'productSkuConfigurations.path',
    'productSkuConfigurations.description',
    'productSkuConfigurations.parent',
    'productSkuConfigurations.children',
    'salesRep',
    'salesRep.id',
    'salesRep.userId',
    'salesRep.user',
    'salesRep.salesRepGroups',
    'salesRep.customers',
  ];

  splitOrderItems: OrderItemModel[] = [];

  productCategories: ProductSkuModel[];

  headers: string[] = ['PRODUCT', 'SKU ID', 'QTY', 'PRICE', 'SUB-TOTAL', 'QTY', 'PRICE', 'SUB-TOTAL'];

  order: OrderModel;

  splitOrder: OrderModel;

  id: number;

  constructor(
    public readonly ovAutoService: OvAutoService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    this.route.params.subscribe(param => {
      this.id = param['id'];
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  quantityChange(splitOrderItem: OrderItemModel, orderItem: OrderItemModel, event: Quantities, splitPicker: boolean) {
    if (event.valid) {
      if (splitPicker) {
        if (event.increment) {
          splitOrderItem.quantity += event.incrementValue;
          orderItem.quantity -= event.incrementValue;
        } else {
          splitOrderItem.quantity -= event.incrementValue;
          orderItem.quantity += event.incrementValue;
        }
      } else if (!splitPicker) {
        if (event.increment) {
          orderItem.quantity += event.incrementValue;
          splitOrderItem.quantity -= event.incrementValue;
        } else {
          orderItem.quantity -= event.incrementValue;
          splitOrderItem.quantity += event.incrementValue;
        }
      }
    }
  }

  back(): void {
    window.history.back();
  }

  getTitle(): string {
    return this.order ? `Split Order: ${this.order.orderCode}` : '';
  }

  getCustomerTitle(): string {
    if (this.order) {
      return `Split Order: ${this.order.orderCode} - ${this.order.customer.name}`;
    }
    return '';
  }

  close(): void {
    window.history.back();
  }

  split(): void {
    this.updateCurrentOrder();
    this.creatSplitOrder();
  }

  updateCurrentOrder(): void {
    this.order.orderItems.forEach(item => {
      this.ovAutoService.hardDelete(OrderItemModel, item.id).then(() => {});
    });
    this.createOrderItems();
  }

  creatSplitOrder(): void {
    this.splitOrder.createDate = new Date().toISOString();
    this.splitOrder.orderDate = this.order.orderDate;
    this.splitOrder.fulfilmentDate = this.order.fulfilmentDate;
    const payload = getCreate(this.splitOrder);
    payload.orderItems = Object.values(this.splitOrder.orderItems)
      .map(o => getCreate(o))
      .filter(o => o.quantity > 0);
    payload.deliveryAddress = this.order.deliveryAddress;
    delete payload['id'];
    delete payload['orderItemsIdList'];
    payload.orderItems.forEach(item => {
      delete item['id'];
    });
    this.ovAutoService.create(OrderModel, payload).then(res => {
      swal.fire({
        title: `Order split to: ${res.orderCode}`,
        showConfirmButton: false,
        type: 'success',
      });
      this.back();
    });
  }

  createOrderItems(): void {
    this.order.orderItems.forEach(orderRow => {
      const newItem = new OrderItemModel();
      newItem.productSku = orderRow.productSku;
      newItem.quantity = orderRow.quantity;
      newItem.orderId = this.order.id;
      newItem.unitPriceIncl = orderRow.unitPriceIncl;
      newItem.unitPriceExcl = orderRow.unitPriceExcl;
      this.ovAutoService.create(OrderItemModel, getCreate(newItem));
    });
  }

  getTotalPriceExcl(orderItems: OrderItemModel[]) {
    return orderItems.reduce((a, b) => a + b.quantity * b.unitPriceExcl, 0);
  }

  getData() {
    this.ovAutoService
      .get({
        entity: OrderModel,
        keys: this.keys,
        id: Number(this.id),
      })
      .then(result => {
        this.order = result;
        this.order.orderItems.sort((a, b) => {
          const fa = a.productSku.name.toLowerCase();
          const fb = b.productSku.name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        this.splitOrder = Object.assign(new OrderModel(), {
          ...this.order,
          id: null,
          orderItems: this.order.orderItems.map(i => Object.assign(new OrderItemModel(), { ...i, id: null, orderId: null, quantity: 0 })),
        });
        this.splitOrderItems = this.splitOrder.orderItems;
      })
      .catch(err => console.error(err));
  }
}
