import { Routes } from '@angular/router';
import { InventoryListComponent } from './list-inventory/inventory-list.component';
import { InventoryAddEditComponent } from './add-edit/add-edit-inventory.component';

export const INVENTORYROUTES: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: InventoryListComponent,
      },
      {
        path: 'edit',
        component: InventoryAddEditComponent,
      },
    ],
  },
];
