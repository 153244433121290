<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="add-edit-header">Selected Customer Details</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngTemplateOutlet="customerDetails"></ng-container>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #customerDetails>
  <mat-card class="mat-elevation-z0 selected-customer-details-card">
    <mat-card-content>
      <form [formGroup]="customerDetailsForm">
        <div>
          <mat-form-field appearance="fill">
            <mat-label>Customer Name</mat-label>
            <input matInput
                   formControlName="name"
                   name="name"
                   id="name"
            >
          </mat-form-field>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="fill">
              <mat-label>Contact Number</mat-label>
              <input matInput
                     formControlName="mobile"
                     name="mobile"
                     id="mobile"
              >
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="fill">
              <mat-label>Lead Time</mat-label>
              <input matInput
                     formControlName="leadTime"
                     name="leadTime"
                     id="leadTime"
              >
            </mat-form-field>
          </div>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Email Address</mat-label>
          <input matInput
                 formControlName="emailAddress"
                 name="emailAddress"
                 id="emailAddress"
          >
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Physical Address</mat-label>
          <input matInput
                 formControlName="address"
                 name="address"
                 id="address"
          >
        </mat-form-field>
      </form>

      <mat-card-actions>
        <div class="edit-actions">
          <button mat-stroked-button color="primary" (click)="onEditClick()">Edit</button>
        </div>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</ng-template>
