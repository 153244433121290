import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { InventoryAccordionService, MappedProducts, OvAutoService } from '@ov-suite/services';
import { ActivatedRoute } from '@angular/router';
import { CustomerModel, ProductSkuModel } from '@ov-suite/models-admin';
import { OrderItemModel, Reason, ReturnItem, ReturnModel } from '@ov-suite/models-order';
import swal from 'sweetalert2';
import { OrderService } from '../../../services/order/order.service';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, from, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'ov-suite-add-edit-return',
  templateUrl: 'add-edit-return.component.html',
  styleUrls: ['./add-edit-return.component.scss'],
})
export class ReturnAddEditComponent<T extends OrderItemModel | ReturnItem> implements OnInit {
  @ViewChild('returnDetails') returnDetails: NgForm;

  customerOptions: Observable<CustomerModel[]>;

  customerSelected: CustomerModel;

  totalIncl = 0;

  totalExcl = 0;

  returnId: number;

  customer: CustomerModel;

  returnObject = new ReturnModel();

  originalReturnObject: ReturnModel;

  originalReturnItemMap: Map<number, ReturnItem> = new Map();

  productSkus: ProductSkuModel[];

  returnReasons: Reason[]

  returnItemMap: Map<number, ReturnItem> = new Map();

  returnForm = this.formBuilder.group({
    id: [{value: '', disabled: true}],
    customer: ['', Validators.required]
  })

  productCategories: MappedProducts[];

  constructor(
    public readonly ovAutoService: OvAutoService,
    private readonly route: ActivatedRoute,
    private readonly ref: ChangeDetectorRef,
    private readonly orderService: OrderService,
    private readonly inventoryAccordionService: InventoryAccordionService<T>,
    private formBuilder: FormBuilder,
  ) {
    this.route.params.subscribe(param => {
      this.returnId = parseInt(param['id']);
    });

    this.customerOptions = this.returnForm.get('customer').valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      filter(customer => !!customer),
      switchMap(customer => {
        return from(this.orderService.listCustomersByName(customer))
      })
    )
  }

  async ngOnInit() {
    this.productCategories = await this.inventoryAccordionService.listProductCategories(null);

    if (this.returnId) {
      await this.prepareUpdateReturn();
    }

    this.returnReasons = await this.orderService.getReasons();
  }

  async prepareUpdateReturn() {
    this.returnObject = await this.orderService.loadReturn(this.returnId);

    this.originalReturnObject = Object.assign({}, this.returnObject);

    this.customerSelected = this.returnObject.customer;
    // Sales Rep will be the same as the original item.

    this.returnForm.patchValue({
      id: this.returnObject.id,
      customer: this.customerSelected.name,
    })

    this.prepareReturnItems();
  }

  selectCustomer(customer: CustomerModel) {
    this.customerSelected = customer;
  }

  processReturnDetails(): boolean {
    if (this.returnForm.invalid) {
      swal.fire({ title: 'Fill in all required fields', type: 'warning' });
      return false;
    }

    if (!this.returnItemMap.size) {
      swal.fire({ title: 'Please add items to this order', type: 'warning' });
      return false;
    }

    const flattenedReturnItems = Array.from(this.returnItemMap.values());

    const quantityChecker = flattenedReturnItems.some(i => {
      return i.quantity > 0;
    });

    if (!quantityChecker) {
      swal.fire({ title: 'Ensure at lease one product has a quantity greater an zero', type: 'warning' });
      return false;
    }

    const reasonChecker = flattenedReturnItems
      .map(i => {
        if (!(i.quantity > 0)) return;
        return i;
      })
      .filter(valid => valid)
      .every(returnItem => {
        return returnItem.reasonId;
      })

    if (!reasonChecker) {
      swal.fire({ title: 'Please ensure all valid return products have reasons', type: 'warning' });
      return false;
    }

    this.returnObject.customer = this.customerSelected;
    this.returnObject.customerId = this.customerSelected.id;
    this.returnObject.salesRepId = 1;

    if (this.returnId) {
      this.returnObject.id = this.returnId;
    } else {
      this.returnObject.createDate = new Date().toISOString();
    }

    return true;
  }

  archiveReturn() {
    swal.fire({
      title: "Are you sure?",
      text: "This return will be archived!",
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.ovAutoService.delete(ReturnModel, this.returnId).then(() => {
          swal.fire({ title: 'Success', text: 'Return successfully archived', type: 'success' })
            .then(() => {
              this.back();
            })
        })
      }
    })
  }

  async save() {
    const validationResult = this.processReturnDetails();

    if (!validationResult) return;

    try {
      if (this.returnId) {
        // UPDATE
        await this.orderService.updateReturn(this.returnObject, this.originalReturnObject, this.returnItemMap, this.originalReturnItemMap)
      } else {
        await this.orderService.createReturn(this.returnObject, this.returnItemMap);
      }

      swal.fire({ title: 'Successfully saved return', type: 'success'}).then(() => this.back())
    } catch(e) {
      console.error(e);
      swal.fire({ title: 'Error occurred', type: 'error' });
    }
  }

  back(): void {
    window.history.back();
  }

  prepareReturnItems() {
    const returnItems = new Map();

    this.returnObject.returnItems.forEach(item => {
      returnItems.set(item.productSkuId, item);
      const clone: ReturnItem = Object.assign({}, item);
      this.originalReturnItemMap.set(clone.productSkuId, clone);
    })

    this.productList(returnItems);
  }

  productList(returnItems: Map<number, ReturnItem>) {
    this.totalExcl = 0;
    this.totalIncl = 0;

    this.returnItemMap = returnItems;

    returnItems.forEach((item: ReturnItem) => {
      this.totalIncl += item.quantity * item.unitPriceIncl;
      this.totalExcl += item.quantity * item.unitPriceExcl;
    })
  }

  getTotalIncl(): string {
    return this.totalIncl.toFixed(2);
  }

  getTotalExcl(): string {
    return this.totalExcl.toFixed(2);
  }

  getVatDiff(): string {
    return (this.totalIncl - this.totalExcl).toFixed(2);
  }

  getVat() {
    return ((this.totalIncl - this.totalExcl) / this.totalIncl * 100).toFixed(2);
  }
}
