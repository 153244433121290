<div class="main-content">
  <div class="main-container">
    <form class="sales-constraint-form" [formGroup]="formGroup" (submit)="submit()">
    <mat-card>
      <mat-card-title>Sales Constraint</mat-card-title>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Name</mat-label>
          <input type="text"
                 placeholder="Search"
                 aria-label="Product"
                 matInput
                 required
                 [formControlName]="'name'">
        </mat-form-field>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Minimum Order Quantity</mat-label>
            <input type="number"
                   [min]="0"
                   placeholder="Quantity"
                   aria-label="How Many Picks"
                   matInput
                   [defaultValue]="0"
                   [formControlName]="'minimum'">
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Maximum Order Quantity</mat-label>
            <input type="number"
                   [min]="0"
                   placeholder="Quantity"
                   aria-label="How Many Picks"
                   matInput
                   [formControlName]="'maximum'">
          </mat-form-field>
    </mat-card>
    <mat-card>
      <mat-card-title>Quantity Constraints</mat-card-title>
        <ov-suite-clean-table
          [dataSource]="constraintsDataSource"
          emptyMessage="No constraints set"
          [columnData]="constraintColumnData"
          [disablePaging]="true"
          [disableSorting]="true"
        ></ov-suite-clean-table>
      <mat-card-actions align="end">
        <button mat-button color="primary" type="button" (click)="addConstraint()">Add Constraint</button>
      </mat-card-actions>
    </mat-card>
    <mat-card>
      <mat-card-title>Product SKUs</mat-card-title>
        <ov-suite-clean-table
          [dataSource]="productSkusDataSource"
          emptyMessage="No product skus assigned"
          [columnData]="productSkuColumnData"
          [disablePaging]="true"
        ></ov-suite-clean-table>
      <mat-card-actions align="end">
        <button mat-button color="primary" type="button" (click)="addProductSku()">Assign to Products</button>
      </mat-card-actions>
    </mat-card>
      <mat-card-actions align="end">
        <button mat-stroked-button color="primary" type="submit">{{id ? 'Update' : 'Add'}} Sales Constraint</button>
      </mat-card-actions>
    </form>
  </div>
</div>
