import { Component, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { ProductSkuModel } from '@ov-suite/models-admin';

@Component({
  selector: 'ov-suite-add-edit-inventory',
  templateUrl: './add-edit-inventory.component.html',
  styleUrls: ['./add-edit-inventory.component.scss'],
})
export class InventoryAddEditComponent implements OnInit {
  constructor(public readonly ovAutoService: OvAutoService) {}

  entity = ProductSkuModel;

  ngOnInit(): void {}

  navigateBack() {
    window.history.back();
  }
}
