import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductSkuModel } from '@ov-suite/models-admin';
import { Grouping, Quantities } from '@ov-suite/models-order';
import { OvAutoService } from '@ov-suite/services';

export interface FilterChange {
  filter: Record<string, string[]>;
  query: Record<string, string[]>;
  search: Record<string, string[]>;
}

@Component({
  selector: 'ov-suite-increment-picker',
  templateUrl: './increment-picker.component.html',
  styleUrls: ['./increment-picker.component.scss'],
})
export class IncrementPickerComponent implements OnInit {
  constructor(public readonly ovAutoService: OvAutoService) {}

  @Input() sku: ProductSkuModel;

  @Input() currentValue: number;

  @Input() overrideIncrementDecrement = false;

  @Input() incrementDisabled = false;

  @Input() decrementDisabled = false;

  @Output() changeValue = new EventEmitter<Quantities>();

  quantities = new Quantities(0, 0, 0, true, 0, true);

  valueValid = true;

  ngOnInit(): void {
    this.quantities = this.getQuantities(this.currentValue, this.sku, true, true, this.incrementDisabled);
  }

  getQuantities(currentValue: number, sku: ProductSkuModel, initial, increment: boolean, disabled: boolean): Quantities {
    if (!(disabled && this.overrideIncrementDecrement)) {
      let groups: Grouping[] = [];

      // Not used anymore, Deprecated
      // if (sku?.inverseProductSkuConfigurations.length === 0 ?? true) {
      //   groups.push(new Grouping(1, null));
      // } else {
      //   groups = sku.inverseProductSkuConfigurations.map(e => new Grouping(e.quantity, e.productSku.salesLimit));
      // }

      const amounts: number[] = [];

      groups.forEach(group => {
        if (group.maxMultiples == null) {
          const amount = Math.floor(currentValue / group.size);
          const low = amount * group.size;
          if (low === currentValue) {
            amounts.push((amount - 1) * group.size);
          }
          amounts.push(low);
          amounts.push((amount + 1) * group.size);
        } else {
          for (let i = 1; i <= group.maxMultiples!; i++) {
            amounts.push(group.size * i);
          }
        }
      });

      const output = new Quantities(0, currentValue, 0, amounts.includes(currentValue, 0), 0, true);

      amounts.forEach(amm => {
        if (amm > currentValue) {
          if (amm < output.high || !output.high) {
            output.high = amm;
          }
        } else if (amm < currentValue) {
          if (amm > output.low || !output.low) {
            output.low = amm < 0 ? 0 : amm;
          }
        }
      });

      this.quantities = output;
      this.currentValue = output.current;
      this.quantities.increment = increment;
      this.quantities.incrementValue = output.high - output.current;
      if (!initial) {
        this.changeValue.emit(this.quantities);
      }
      return output;
    }
    return this.quantities;
  }
}
