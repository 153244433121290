    <label *ngIf="title" class="mt-2" [for]="id"
      >{{title}}
      <span
        *ngIf="tooltip"
        aria-hidden="true"
        class="fa fa-info-circle"
        placement="right-top"
        [ngbTooltip]="!!tooltip ? tipContent : ''"
      ></span>
      <ng-template #tipContent><div [innerHTML]="tooltip"></div></ng-template>
    </label>
    <div
      [ngClass]="{
        'input-parent-disabled': disabled,
        'border-danger': danger
      }"
      [ngStyle]="style"
      class="input-parent"
    >
      <div class="input-group">
        <input
          [id]="id"
          [type]="'text'"
          class="form-control"
          [disabled]="disabled"
          [placeholder]="!!placeholder ? placeholder : ''"
          [(ngModel)]="displayable"
          (ngModelChange)="valueChange($event)"
        />
        <div class="input-group-append">
          <button class="btn-clear fa fa-times-circle-o" (click)="clearInput()" type="button"></button>
        </div>
      </div>
      <div class="dropdown" [ngClass]="{'hidden': hideDropdown}">
        <div *ngFor="let item of filterData" [ngValue]="item" class="dropdown-item" (click)="selectFromDropDown(item)" >
          <span>
            {{getDropDownItemDescription(item)}}
          </span>
        </div>
      </div>
      </div>
