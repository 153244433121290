import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { v4 } from 'uuid';
import { Constructor, HasId } from '@ov-suite/ov-metadata';
import { OvAutoService, OvAutoServiceListParams } from '@ov-suite/services';
import * as _ from 'lodash';
import { QueryParams } from '@ov-suite/helpers-shared';
import { AbstractValueAccessor, MakeProvider } from '../input/abstruct-value-accessor';

type DisplayFunction<T> = (item: T) => string;

@Component({
  selector: 'ov-suite-predictive-text-dropdown',
  templateUrl: './predictive-text-dropdown.component.html',
  styleUrls: ['./predictive-text-dropdown.component.scss'],
  providers: [MakeProvider(PredictiveTextDropdownComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PredictiveTextDropdownComponent<T extends HasId> extends AbstractValueAccessor<T | boolean> {
  constructor(public readonly ovAutoService: OvAutoService, private readonly changeDetector: ChangeDetectorRef) {
    super();
  }

  @Input() id: string = v4();

  @Input() data: T[]; // data that will populate the dropdown

  @Input() placeholder: string | number;

  @Input() tooltip: string;

  @Input() disabled: boolean;

  @Input() danger: boolean;

  @Input() title: string;

  @Output() submitOverride = new EventEmitter();

  @Input() style: Record<string, unknown> = {}; // Adds additional unique styling

  @Input() searchKeys: string[]; // keys to validate the search value against

  @Input() dropdownDisplayKey: string; // values that will be displayed in the dropdown

  @Input() displayFunction: DisplayFunction<T>;

  @Input() formClass: Constructor<T>;

  @Input() limit: number;

  @Input() query: Record<string, QueryParams[]>; // adds additional criteria to the records returned

  filterData = [];

  hideDropdown = true;

  displayable = '';

  searchString = '';

  debounceFetch: Function = _.debounce(() => this.fetchData(), 200, { leading: false, trailing: true, maxWait: 3000 });

  @Input() set initial(data: T) {
    if (data) this.displayable = this.displayFunction(data);
  }

  fetchData() {
    const params: OvAutoServiceListParams<T> = {
      entity: this.formClass,
      search: {},
      query: this.query,
      limit: this.limit,
    };

    this.searchKeys.forEach(key => {
      params.search[key] = [this.searchString];
    });

    this.ovAutoService.list(params).then(data => {
      this.filterData = data.data;
      this.changeDetector.detectChanges();
    });
  }

  valueChange(item) {
    this.searchString = item;
    this.debounceFetch();
    this.filterData = [];
    this.hideDropdown = false;
  }

  selectFromDropDown(item) {
    this.displayable = this.displayFunction(item);
    this.hideDropdown = true;
    if (item !== undefined) {
      this.submitOverride.emit(item);
      this.value = item;
    }
  }

  getDropDownItemDescription(item) {
    return this.displayFunction(item);
  }

  clearInput() {
    this.hideDropdown = true;
    this.filterData = [];
    this.displayable = '';
  }
}
