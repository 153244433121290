<div class="main-content">

  <div class="main-container-no-table">
    <div class="page-title">
        <span class="page-title-text">
          {{ id ? 'Update Order' : 'Create Order' }}
        </span>
    </div>

    <!-- Order Details Section -->
    <div class="order-details row">
      <mat-card class="order-details-card col-md-12">
        <mat-card-title class="add-edit-header">
          Order Details
        </mat-card-title>

        <mat-card-content>
          <form [formGroup]="orderForm">
            <div class="row">
              <ng-container *ngIf="id">
                <div class="col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Order ID</mat-label>
                    <input matInput
                           placeholder="Placeholder"
                           formControlName="id"
                           name="id"
                           id="id"
                    >
                  </mat-form-field>
                </div>
              </ng-container>
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label>External Order</mat-label>
                  <input matInput
                         formControlName="externalOrderNo"
                         name="externalOrderNo"
                         id="externalOrderNo"
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Due Date</mat-label>
                  <input matInput
                         [matDatepicker]="picker"
                         formControlName="dueDate"
                         id="orderDate"
                         name="orderDate"
                  >
                  <mat-datepicker-toggle matSuffix
                                         [for]="picker">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Order Priority</mat-label>
                  <mat-select
                    id="priority"
                    formControlName="priority"
                  >
                    <mat-option *ngFor="let priority of priorities"
                                [value]="priority.id"
                    >
                      {{ priority.text }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Customer</mat-label>
                  <input matInput
                         id="customer"
                         name="customer"
                         formControlName="customer"
                         [matAutocomplete]="auto1"
                  >
                  <mat-autocomplete #auto1="matAutocomplete">
                    <mat-option *ngFor="let customer of customerOptions | async"
                                [value]="customer.name.concat( ' - ' + customer?.customerCode)"
                                (onSelectionChange)="selectCustomer(customer)"
                    >
                      <div class="selected-customer">
                        {{ customer.name }}
                        <span class="customer-code">{{ customer.customerCode }}</span>
                      </div>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label>Sales Representative</mat-label>
                  <input matInput
                         formControlName="salesRep"
                         name="salesRep"
                         id="salesRep"
                         [matAutocomplete]="auto2"
                  >
                  <mat-autocomplete #auto2="matAutocomplete">
                    <mat-option *ngFor="let rep of salesRepOptions | async"
                                [value]="rep.user.firstName + ' ' + rep.user.lastName"
                                (onSelectionChange)="selectSalesRep(rep)"
                    >
                      {{ rep.user.firstName }} {{ rep.user.lastName }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <mat-checkbox
                  formControlName="captureSupergroup"
                  (click)="onSupergroupCaptureChange()"
                >
                  Capture on Super Group
                </mat-checkbox>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>

    <br>
      <ov-suite-order-summary [orderItemMapSubject]="productCategoryList.orderItemMapSubject"
                              [splitOrderItemMap]="splitOrderItemMap">
      </ov-suite-order-summary>
    <br>

    <!-- Product Categories And Product Skus-->
    <div>
      <mat-card>
        <mat-card-title>
          <div class="details">
            <div class="add-edit-header">Order Products</div>
            <div class="orders-total-card">
              <span class="orders-total-label">Total:</span>
              <span class="orders-total-value">
              R {{ getTotalExcl() }}
            </span>
            </div>
          </div>
        </mat-card-title>

        <br>

        <mat-card-content>
          <ov-suite-product-category-list
                  [customerChangeSubject]="customerChangeSubject"
                  #productCategoryList
          ></ov-suite-product-category-list>
        </mat-card-content>

        <br>

        <mat-card-content>
          <div class="orderSummary">
            <table class="summaryTable">
              <tr>
                <td class="orders-total-label">Sub Total:</td>
                <td class="orders-total-value">R {{ getTotalExcl() }}</td>
              </tr>
              <tr>
                <td class="orders-total-label">Vat Amount:</td>
                <td class="orders-total-value">R {{ getVatDiff() }}</td>
              </tr>
              <tr>
                <td class="orders-total-label total">Total Amount:</td>
                <td class="orders-total-value pull-right sales-contrast">R {{ getTotalIncl() }}</td>
              </tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <br>

    <div>
      <ov-suite-order-details (deliveryDetailsOutput)="adjustDeliveryDetails($event)"
                              [deliveryData]="deliveryDetailsData"
                              [deliveryAddresses]="customerDeliveryAddresses"
      >
      </ov-suite-order-details>
    </div>
  </div>

  <!-- Top Actions Fixed -->
  <div class="top-action">
    <button mat-stroked-button color="accent" (click)="back()">Cancel</button>
    <div class="action-button-container">
      <button *ngIf="id" mat-stroked-button color="warn" (click)="archiveOrder()">Archive</button>
      <button mat-stroked-button color="primary" (click)="save()">Save</button>
    </div>
  </div>
</div>
