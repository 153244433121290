import { Component, Inject, Input, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { FormBuilder, FormControl } from '@angular/forms';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  from,
  Observable,
  switchMap,
} from 'rxjs';
import { ProductSkuModel } from '@ov-suite/models-admin';
import { MatOptionSelectionChange } from '@angular/material/core';
import { LoadAllocation } from '@ov-suite/models-warehouse';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ov-suite-add-product-sku',
  templateUrl: './add-product-sku-modal.component.html',
  styleUrls: ['./add-product-sku-modal.component.css']
})
export class AddProductSkuModalComponent implements OnInit {

  @Input() load: LoadAllocation;

  formGroup = this._formBuilder.group({
    product: new FormControl(),
  });

  productLabel = '';

  productOptions$: Observable<ProductSkuModel[]>;

  productSelected$: BehaviorSubject<ProductSkuModel> = new BehaviorSubject<ProductSkuModel>(null);

  productToAdd: ProductSkuModel;

  constructor(
    private _formBuilder: FormBuilder,
    private readonly ovAutoService: OvAutoService,
    public dialogRef: MatDialogRef<AddProductSkuModalComponent>,
    @Inject(MAT_DIALOG_DATA) public loadId: number,
    ) {

  }

  ngOnInit(): void {
    this.productOptions$ = this.formGroup.get('product').valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      filter(product => !!product),
      switchMap(product => {
        return from(this.ovAutoService.list({
          entity: ProductSkuModel,
          limit: 5,
          relations: ['containerConfigurationItems', 'containerConfigurationItems.inventoryContainerConfiguration'],
          search: {
            name: [product]
          },
        }).then(response => response.data))
      })
    )
  }

  select(input: MatOptionSelectionChange<ProductSkuModel>) {
    this.formGroup.get('product').setValue(input.source.getLabel());
    this.productSelected$.next(input.source.value);
  }

  submit() {
    this.dialogRef.close(this.productSelected$.value);
  }

  close() {
    this.dialogRef.close();
  }
}
