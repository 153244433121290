import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { OrderModel } from '@ov-suite/models-order';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import swal from 'sweetalert2';
import { CustomerModel } from '@ov-suite/models-admin';
import { OverrideModalComponent } from '../override-modal/override-modal.component';

@Component({
  selector: 'ov-suite-schedule-order-modal',
  templateUrl: './schedule-order-modal.component.html',
  styleUrls: ['./schedule-order-modal.component.scss'],
})
export class ScheduleOrderModalComponent implements OnInit {
  constructor(
    public ovAutoService: OvAutoService,
    protected modalService: NgbModal,
    private readonly apollo: Apollo,
  ) {}

  @Input() order: OrderModel;

  @Input() overridden: boolean;

  customer: CustomerModel;

  checkingCreditLimit = true;

  message = 'Are you sure you want to release the order for transport scheduling?';

  displayFunctionSalesReps = item => `${item.user.firstName} ${item.user.lastName}`;

  confirm() {
    this.apollo
      .query({
        query: gql(`query releaseOrderForScheduling($id: Int!) {
        releaseOrderForScheduling(id: $id)
      }`),
        variables: { id: this.order.id },
      })
      .subscribe(res => {
        if (res.data) {
          swal.fire({
            title: `Order: ${this.order.id} released!`,
            showConfirmButton: false,
            type: 'success',
          });
          this.close();
        }
      });
  }

  navigateBack() {
    window.history.back();
  }

  close = () => {
    this.modalService.dismissAll();
  };

  ngOnInit(): void {
    this.getCustomer(this.order.customer.id);
  }

  getCustomer(id: number): void {
    this.ovAutoService
      .get({
        entity: CustomerModel,
        id,
      })
      .then(result => {
        this.customer = result;
        const orderTotal = this.order.orderItems.reduce((p, c) => p + c.unitPriceExcl, 0);
        if (orderTotal > this.customer.creditLimit && !this.overridden) {
          this.modalService.dismissAll();
          const modalRef = this.modalService.open(OverrideModalComponent, { size: 'lg' });
          modalRef.componentInstance.order = this.order;
          modalRef.componentInstance.header = 'Credit Limit Reached';
          modalRef.componentInstance.message =
            'Please note this client has reached their credit limit and cannot proceed with an order until resolved. Contact the client or alternatively, request admin to override the limit. ';
          modalRef.componentInstance.modal = ScheduleOrderModalComponent;
        } else {
          this.checkingCreditLimit = false;
        }
      })
      .catch(err => console.error(err));
  }
}
