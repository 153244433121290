import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ov-suite-add-pick',
  templateUrl: './add-constraint-modal.component.html',
  styleUrls: ['./add-constraint-modal.component.css']
})
export class AddConstraintModalComponent {

  formGroup = new FormGroup({
    value: new FormControl(),
    allowMultiples: new FormControl(false),
  });

  constructor(
    public dialogRef: MatDialogRef<AddConstraintModalComponent>,
  ) {}

  submit() {
    this.dialogRef.close(this.formGroup.value);
  }

  close() {
    this.dialogRef.close();
  }

}
