import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderItemModel, OrderModel } from '@ov-suite/models-order';
import { ConfirmationDialogService, OvAutoService } from '@ov-suite/services';
import { ColumnData, doubleToRandString } from '@ov-suite/helpers-shared';
import { MatCustomDataSource } from '@ov-suite/ui';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'ov-suite-order-items-details-modal',
  templateUrl: './order-items-details-modal.component.html',
  styleUrls: [ './order-items-details-modal.component.css' ]
})
export class OrderItemsDetailsModalComponent implements OnInit {
  @Input() order: OrderModel;

  @Output() dialogEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  alteredOrder = false;

  orderItems: OrderItemModel[];

  dataSource: MatCustomDataSource<OrderItemModel> = new MatCustomDataSource<OrderItemModel>([]);

  originalData: OrderItemModel[];

  search: string;

  updating = false;

  columnData: ColumnData<OrderItemModel>[] = [
    {
      key: 'productSku.name',
      title: 'Product Name',
      type: 'deep-string',
    },
    {
      key: 'productSku.sku',
      title: 'SKU',
      type: 'deep-string',
    },
    {
      key: 'quantity',
      title: 'Quantity',
      type: 'number',
      editable: true,
    },
    {
      keys: [ 'processedQuantity' ],
      title: 'Processed',
      type: 'other',
      action: item => {
        if (item.processedQuantity) {
          return `${item.processedQuantity}`;
        }
        return `0`;
      }
    },
    {
      keys: [ 'unitPriceExcl' ],
      title: 'Unit Price',
      type: 'other',
      action: item => `${doubleToRandString(item.unitPriceExcl)} `,
    },
    {
      keys: [ 'quantity', 'unitPriceExcl' ],
      title: 'Total',
      type: 'other',
      action: item => doubleToRandString(item.quantity * item.unitPriceExcl),
    },
    {
      title: 'Actions',
      type: 'button-bar',
      disableSorting: true,
      keys: [],
      buttons: [
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'file-1',
          actionType: 'custom',
          action: orderItem => {
            this.confirmationDialogService.openConfirmRemovalDialog().subscribe(remove => {
              if (remove) {
                this.removeOrderItem(orderItem);
              }
            });
          }
        },
      ],
    }
  ];

  constructor(private readonly ovAutoService: OvAutoService, private readonly confirmationDialogService: ConfirmationDialogService) {}

  async ngOnInit() {
    await this.getOrderItems();

    this.dataSource.sortData = (items, matSort) => {
      if (matSort && matSort.active && matSort.direction) {
        return this.sortOrderItems(items, matSort);
      }
      return items;
    };
  }

  sortOrderItems(orderItems: OrderItemModel[], sort?: MatSort) {
    if (sort && sort.direction) {
      const up = sort.direction === 'asc' ? 1 : -1;
      switch (sort.active) {
        case 'Product Name':
          orderItems.sort((a, b) => (a.productSku.name.toLowerCase() > b.productSku.name.toLowerCase() ? up : -up));
          break;
        case 'SKU':
          orderItems.sort((a, b) => (a.productSku.sku.toLowerCase() > b.productSku.sku.toLowerCase() ? up : -up));
          break;
        case 'Quantity':
          orderItems.sort((a, b) => (a.quantity > b.quantity) ? up : -up);
          break;
        case 'Processed':
          orderItems.sort((a, b) => (a.processedQuantity > b.processedQuantity ? up : -up));
          break;
        case 'Unit Price':
          orderItems.sort((a, b) => (a.unitPriceExcl > b.unitPriceExcl ? up : -up));
          break;
        case 'Total':
          orderItems.sort((a, b) => ((a.quantity * a.unitPriceExcl) > (b.quantity * b.unitPriceExcl) ? up : -up));
          break;
      }
    }

    return orderItems;
  }

  onSearchChange(input: string) {
    if (input) {
      const loweredInput = input.toLowerCase();
      this.dataSource.data = this.originalData.filter(orderItem => {
        return orderItem.productSku.sku.toLowerCase().includes(loweredInput)
          || orderItem.productSku.name.toLowerCase().includes(loweredInput);
      });
    } else {
      this.dataSource.data = this.originalData;
    }
  }

  async getOrderItems() {
    await this.ovAutoService.list({
      entity: OrderItemModel,
      query: {
        orderId: [ this.order.id ]
      }
    }).then(res => {
      this.originalData = res.data;
      this.dataSource.data = this.originalData;
    });
  }

  removeOrderItem(orderItem: OrderItemModel) {
    const index = this.originalData.indexOf(orderItem);

    this.ovAutoService.delete(OrderItemModel, orderItem.id).then(() => {
      this.originalData.splice(index, 1);
      this.dataSource.data = this.originalData;
    });
  }

  async updateOrderItem(orderItem: OrderItemModel) {
    if (!this.updating) {
      this.updating = true;

      await this.ovAutoService.update(OrderItemModel, {
        id: orderItem.id,
        quantity: orderItem.quantity
      });

      this.alteredOrder = true;
    }
  }

  closeDialog() {
    this.dialogEmitter.emit(this.alteredOrder);
  }
}
