<mat-accordion>
  <mat-expansion-panel *ngFor="let item of productCategories">
    <mat-expansion-panel-header (click)="onCategorySelected(item)">
      <mat-panel-title>{{ item.category.name }}</mat-panel-title>
      <mat-panel-description>
        <div class="panel-description-chips">
          <mat-chip>
            {{ getQuantityTotals(item.category) }}
          </mat-chip>
          <mat-chip>
            {{ getCategoryTotals(item.category) }}
          </mat-chip>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div *ngIf="selectedCategory && selectedCategory.categories.length > 0">
        <!-- Recursively calls this component if there are categories within categories -->
        <ov-suite-accordion-category
          [customerChangeSubject]="customerChangeSubject"
          [productCategories]="selectedCategory.categories"
          [type]="type"
          [productItemMap]="productItemMap"
          [productListSubject]="productListSubject"
          [validChangeSubject]="validChangeSubject"
        >
        </ov-suite-accordion-category>
      </div>
      <div *ngIf="item.skus.length > 0">
        <!-- If type is order, render order table -->
        <ng-container *ngIf="type === 'order'">
          <ng-container
            *ngTemplateOutlet="orderTableTemplate; context:{ $implicit: item.skus }">
          </ng-container>
        </ng-container>
        <!-- If type is return, render return table -->
        <ng-container *ngIf="type === 'return'">
          <ng-container
            *ngTemplateOutlet="returnTableTemplate; context:{ $implicit: item.skus }">
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>

<!-- Order Section -->
<ng-template #orderTableTemplate let-products>
  <table mat-table [dataSource]="products" class="mat-elevation-z0">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let product" class="accordion-product-name"> {{ product.name }} </td>
    </ng-container>

    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef> Sku </th>
      <td mat-cell *matCellDef="let product" class="accordion-product-name"> {{ product.skuId ?? ' - ' }} </td>
    </ng-container>

    <ng-container matColumnDef="quantityOnHand">
      <th mat-header-cell *matHeaderCellDef> Available </th>
      <td mat-cell *matCellDef="let product"> {{ product['quantityOnHand'] }} </td>
    </ng-container>

    <ng-container matColumnDef="test">
      <th mat-header-cell *matHeaderCellDef> Quantity </th>
      <td mat-cell *matCellDef="let product">
        <ng-container *ngIf="product['inclPrice']; else disabledQuantityPicker">
          <ov-suite-quantity-picker
            [productSku]="getProductModel(product.model)"
            [value]="productItemMap?.get(product.id)?.quantity ?? 0"
            (onChange)="quantityChange(product, $event)"
          >
          </ov-suite-quantity-picker>
        </ng-container>
        <ng-template #disabledQuantityPicker>
          <div class="no-pricing-quantity warning-no-price">
            No Pricing Configured
          </div>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="inclPrice">
      <th mat-header-cell *matHeaderCellDef> Unit Price </th>
      <td mat-cell *matCellDef="let product;">
        <ng-container *ngIf="product['exclPrice']; else disablePrice">
          {{ formatPrice(product['exclPrice']) }}
        </ng-container>
        <ng-template #disablePrice>
          <div class="warning-no-price">
            N/A
          </div>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef> Total </th>
      <td mat-cell *matCellDef="let product" class="sales-contrast"> R {{ getProductItemTotal(product) }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-template>

<!-- Return Section -->
<ng-template #returnTableTemplate let-products>
  <table mat-table [dataSource]="products" class="mat-elevation-z0">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let product"> {{ product['name'] }} </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let product">
        <ov-suite-quantity-picker
          [productSku]="getProductModel(product.model)"
          [value]="productItemMap.get(product.id)?.quantity ?? 0"
          (onChange)="quantityChange(product, $event)"
        >
        </ov-suite-quantity-picker>
      </td>
    </ng-container>

    <ng-container matColumnDef="reason">
      <th mat-header-cell *matHeaderCellDef>Reason</th>
      <td mat-cell *matCellDef="let product">
        <mat-form-field class="reason-dropdown">
          <mat-select [ngModel]="productItemMap.get(product.id)?.['reasonId']"
                      (ngModelChange)="reasonChange(product, $event)"
          >
            <mat-option *ngFor="let reason of returnReasons"
                        [value]="reason.id"
            >
              {{ reason.reason }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="returnAmount">
      <th mat-header-cell *matHeaderCellDef> Unit Price</th>
      <td mat-cell *matCellDef="let product"> R {{ formatPrice(product['inclPrice']) }} </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef> Total</th>
      <td mat-cell *matCellDef="let product" class="sales-contrast"> R {{ getProductItemTotal(product) }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-template>
