import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderItemModel } from '@ov-suite/models-order';
import { FormBuilder } from '@angular/forms';
import _ from 'lodash';

interface SplitProductQuantity {
  productId: number;
  quantity: number;
  note: string;
}

@Component({
  selector: 'ov-suite-split-order-item-quantity',
  templateUrl: './split-order-item-quantity.component.html',
  styleUrls: ['./split-order-item-quantity.component.css']
})
export class SplitOrderItemQuantityComponent implements OnInit {
  @Input() selectedOrderItem: OrderItemModel;

  @Input() splitOrderItem: OrderItemModel;

  @Output() splitOrderItemOutput: EventEmitter<OrderItemModel> = new EventEmitter<OrderItemModel>();

  productSkuName: string;

  maxQuantity: number;

  form = this.formBuilder.group({
    productId: [],
    note: [],
    quantity: [],
  })

  constructor(private readonly formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.productSkuName = this.selectedOrderItem.productSku.name;
    this.maxQuantity = this.selectedOrderItem.quantity;

    this.form.patchValue({
      productId: this.selectedOrderItem.productSkuId,
      note: '',
      quantity: 0,
    })

    if (this.selectedOrderItem.split) {
      this.form.patchValue({
        note: this.selectedOrderItem.note,
        quantity: this.selectedOrderItem.quantity,
      })
    }

    this.form.valueChanges.subscribe(changes => {
      const splitOrderItem = this.prepareSplitOrderItem(changes);
      this.splitOrderItemOutput.emit(splitOrderItem);
    })
  }

  prepareSplitOrderItem(item: SplitProductQuantity) {
    const clone = _.cloneDeep(this.selectedOrderItem);

    if (!this.splitOrderItem) {
      delete clone.id;
    } else {
      this.selectedOrderItem.id = this.splitOrderItem.id;
    }

    clone.split = true;
    clone.quantity = item.quantity;
    clone.note = item.note;

    return clone;
  }
}
