<mat-card class="mat-elevation-z0 product-split-quantity-card">
  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <p class="product-name">{{ productSkuName }}</p>
        </div>
        <div class="col">
          <mat-form-field appearance="fill">
            <mat-label>Quantity</mat-label>
            <input matInput
                   formControlName="quantity"
                   name="quantity"
                   id="quantity"
                   type="number"
                   [max]="maxQuantity"
                   min="0"
            >
          </mat-form-field>
        </div>
      </div>
      <mat-form-field class="note-text-area" appearance="fill">
        <mat-label>Line Note</mat-label>
        <textarea matInput
               formControlName="note"
               name="note"
               id="note"
               type="text"
        >
        </textarea>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>

<!--{{ selectedOrderItem | json }}-->
