import { Component, OnInit } from '@angular/core';
import { HierarchyTab } from '@ov-suite/ui';
import { OvAutoService } from '@ov-suite/services';
import { ColumnData } from '@ov-suite/helpers-shared';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCategory, ProductSkuModel, toDecimal } from '@ov-suite/models-admin';

@Component({
  selector: 'ov-suite-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss'],
})
export class InventoryListComponent implements OnInit {
  formClass = ProductSkuModel;

  entity = ProductCategory;

  filter?: Record<string, [string]>;

  currentData: ProductSkuModel[] = [];

  tabs: HierarchyTab[] = [
    { title: 'Products', iconClass: '', path: '/inventory-product' },
    { title: 'Locations', iconClass: '', path: '/inventory-locations' },
  ];

  constructor(public readonly ovAutoService: OvAutoService, private readonly router: Router, protected route: ActivatedRoute) {}

  columns: ColumnData<ProductSkuModel>[] = [
    {
      title: 'Category',
      type: 'other',
      action: item => {
        if (item.category) {
          return item.category.name;
        }
        return ' - ';
      },
      filterKey: 'category.name',
      keys: ['category.name'],
    },
    {
      key: 'sku',
      title: 'SKU',
      type: 'string',
    },
    {
      key: 'name',
      title: 'Name',
      type: 'string',
    },
    {
      title: 'Size',
      type: 'other',
      filterKey: 'weight',
      action: item => {
        if (item.weight) {
          if (item.weight >= 1_000_000) {
            return `${toDecimal(item.weight / 1_000_000)} t`;
          } else if (item.weight >= 1_000) {
            return `${toDecimal(item.weight / 1_000)} kg`;
          } else if (item.weight >= 0) {
            return `${item.weight} g`;
          }
          return `${toDecimal(item.weight * 1_000)} mg`;
        }
        return '';
      },
      keys: ['weight'],
      disableFiltering: true,
    },
    {
      key: 'quantityOnHand',
      title: 'On Hand',
      type: 'string',
      disableFiltering: true,
    },
    {
      key: 'quantityReserved',
      title: 'Reserved',
      type: 'string',
      disableFiltering: true,
    },
    {
      key: 'quantityOnSalesOrder',
      title: 'Confirmed',
      type: 'string',
      disableFiltering: true,
    },
    {
      key: 'quantityOnSalesOrder',
      title: 'Planned Receipts',
      type: 'string',
      disableFiltering: true,
    },
    {
      key: 'quantityFree',
      title: 'Available to Promise',
      type: 'string',
      disableFiltering: true,
    },
    {
      title: 'Actions',
      type: 'buttons',
      hideColumnKey: 'actions',
      disableSorting: true,
      disableFiltering: true,
      keys: [],
      buttons: [
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'fa fa-pencil',
          tooltip: 'View',
          tooltipClass: 'orderlink',
          action: async (item, apiService) => {
            await this.router.navigateByUrl(`inventory/edit?id=${item.id}`);
          },
        },
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'fa fa-share text-white',
          tooltip: 'Request stock',
          tooltipClass: 'orderlink',
          action: (item, { ngbModal }) => {
            // todo: to be implemented in phase 2
          },
        },
      ],
    },
  ];

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      const id = param['_parentId'];
      this.filter = { categoryId: [id ? id : null] };
    });
  }
}
