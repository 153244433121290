<div class="filter-tabs">
  <div
    *ngFor="let item of tabs; let i = index"
    class="ht-block"
  >
    <div [ngClass]="{'ht-selected': item.value === active?.value }">
      <a (click)="switchTab(item)">
        {{ item?.title }}
        <i *ngIf="item.iconClass" [class]="item.iconClass" aria-hidden="true"></i>
      </a>
    </div>

  </div>
</div>

<!--[ngClass]="{ 'ht-selected': item.value === ((item.ignoreSubPath) ? ignoreSubBath(active): active) }"-->
<!--[style.border-bottom]="item.path === ((item.ignoreSubPath) ? ignoreSubBath(active): active ) ? '3px solid '+highlightColor : 'none'"-->
<!--[ngClass]="{ 'font-weight-bold': item.path === ((item.ignoreSubPath) ? ignoreSubBath(active): active) }"-->
