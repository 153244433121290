<div class="main-content">
  <ov-suite-filter-tabs (selectedTab)="setFilter($event)" [tabs]="tabs"> </ov-suite-filter-tabs>
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'Order List'"
      [formClass]="formClass"
      [overrideColumnData]="columns"
      [rowData]="rows"
      [hasBulkUpload]="false"
      [excludeColumns]="4"
      [hideColumnKeys]="['id', 'address', 'salesRep']"
      [orderColumn]="'id'"
      [defaultOrderDirection]="'DESC'"
      [showTopBar]="true"
      [selectableRows]="true"
      [editableRows]="true"
      [hideAddButton]="false"
      [filterEnabled]="true"
      [filter]="filter"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>

<ng-template #orderItemsDialog>
  <ov-suite-order-items-details-modal
    [order]="selectedOrder"
    (dialogEmitter)="closeOrderItemsDialog($event)"
  >
  </ov-suite-order-items-details-modal>
</ng-template>

<ng-template #integrationExceptionAction>
  <ov-suite-integration-exception-dialog
    [order]="selectedIntegrationOrder"
    (closeEmission)="closeDialog($event)"
  ></ov-suite-integration-exception-dialog>
</ng-template>
