import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomerModel } from '@ov-suite/models-admin';
import { getUrl } from '@ov-suite/helpers-angular/lib/get-url.helper';

@Component({
  selector: 'ov-suite-customer-details-edit',
  templateUrl: './customer-details-edit.component.html',
  styleUrls: ['./customer-details-edit.component.css']
})
export class CustomerDetailsEditComponent implements OnInit {
  customerId: number;

  @Input() set selectedCustomer(customer: CustomerModel) {
    if (customer) {
      this.customerId = customer.id;

      this.customerDetailsForm.patchValue({
        name: customer.name,
        address: customer.physicalAddress.getOneLiner() ?? customer.defaultDeliveryAddress.getOneLiner(),
        leadTime: customer.leadTime,
        mobile: customer.mobileNumber,
        emailAddress: customer.primaryEmail
      })
    }
  }

  customerDetailsForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.customerDetailsForm = this.formBuilder.group({
      name: [''],
      address: [''],
      leadTime: [''],
      mobile: [''],
      emailAddress: ['']
    })
  }

  ngOnInit(): void {}

  onEditClick() {
    window.location.assign(`${getUrl('admin')}/customer/edit?id=${(this.customerId)}`);
  }
}
