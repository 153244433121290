<div class="table-input">
  <button  mat-icon-button (click)="changeAmount(quantities.low)">
    <ng-container *ngIf="quantities.valid; else invalidLow">
      <i *ngIf="quantities.low || quantities.low === 0; else lowDisabled" class="icon-danger; fa fa-minus"></i>

      <ng-template #lowDisabled>
        <i class="icon-danger; fa fa-minus text-gray"></i>
      </ng-template>
    </ng-container>

    <ng-template #invalidLow>
      <span class="icon-danger">{{ quantities.low }}</span>
    </ng-template>

  </button>
  <mat-form-field class="availability-form-field" appearance="none">
    <input matInput
           [ngModel]="quantities.current"
           (ngModelChange)="changeAmount($event)"
           name="quantity"
           type="number"
           class="availability-input"
    >
  </mat-form-field>
  <button mat-icon-button (click)="changeAmount(quantities.high)">
    <ng-container *ngIf="quantities.valid; else invalidHigh">
      <i *ngIf="quantities.high || quantities.high === 0; else highDisabled" class="icon-success; fa fa-plus"></i>

      <ng-template #highDisabled>
        <i class="icon-success; fa fa-plus text-gray"></i>
      </ng-template>
    </ng-container>

    <ng-template #invalidHigh>
      <span class="icon-danger">{{ quantities.high }}</span>
    </ng-template>
  </button>
</div>
