<div class="main-content">
  <div class="main-container">
    <ov-suite-hierarchy-table [title]="'Sales Constraints'"
                              [formClass]="formClass"
                              [excludeColumns]="4"
                              [hasBulkExport]="false"
                              [overrideColumnData]="columnData"
                              [hasBulkUpload]="false">
    </ov-suite-hierarchy-table>
  </div>
</div>
