import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FilterTab } from '@ov-suite/ui';

@Component({
  selector: 'ov-suite-filter-tabs',
  templateUrl: './filter-tabs.component.html',
  styleUrls: ['./filter-tabs.component.scss'],
})
export class FilterTabsComponent implements OnInit {
  @Output() selectedTab = new EventEmitter<FilterTab>();

  active: FilterTab = null;

  @Input() tabs: FilterTab[] = [];

  highlightColor = '#009de6';

  constructor() {}

  ngOnInit() {
    // this.setFilter(this.active);
    // // set initial value
    // if (this.router.url.includes('?')) {
    //   this.active = this.router.url.split('?')[0];
    //   if (this.active.split('/').length > 1) {
    //     this.active = `/${this.active.split('/')[1]}`;
    //   }
    // } else {
    //   this.active = `${this.router.url}`;
    // }
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     if (event.url.includes('?')) {
    //       this.active = event.url.split('?')[0];
    //     } else {
    //       this.active = `/${event.url.split('/')[1]}`;
    //     }
    //   }
    // });
    //
    // // dynamic highlight color changer
    // // @ts-ignore
    // const [element] = document.getElementsByClassName('sidebar');
    // if (element) {
    //   const className = element.getAttribute('data-active-color');
    //   const style = getComputedStyle(document.body);
    //   const hexColor = style.getPropertyValue(`--${className}`).trim();
    //   if (hexColor) {
    //     this.highlightColor = hexColor;
    //   }
    // }
  }

  switchTab(tab: FilterTab) {
    this.active = tab;
    this.selectedTab.emit(this.active);
  }
}
