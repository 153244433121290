import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  InventoryAccordionService,
  MappedProducts,
  OvAutoService,
} from '@ov-suite/services';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerDeliveryAddress, CustomerModel } from '@ov-suite/models-admin';
import { OrderModel, OrderItemModel, SalesRepModel, ReturnItem } from '@ov-suite/models-order';
import swal from 'sweetalert2';
import {  OrderPriorityDropDownConstant } from '@ov-suite/helpers-shared';
import { OrderService } from '../../../services/order/order.service';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, from, Observable, switchMap } from 'rxjs';
import { DeliveryOutput } from '../delivery-details/delivery-details.component';
import { AddressModel } from '@ov-suite/models-shared';
import { ProductCategoryListComponent } from "../product-category-list/product-category-list.component";

export type DeliveryDetailsData = {
  deliveryAddress: AddressModel;
  specialInstructions: string;
}

@Component({
  selector: 'ov-suite-add-edit-order',
  templateUrl: 'add-edit-order.component.html',
  styleUrls: [ './add-edit-order.component.scss' ],
})

export class OrderAddEditComponent<T extends OrderItemModel | ReturnItem> implements OnInit, OnDestroy {
  @ViewChild(ProductCategoryListComponent) productCategoryList: ProductCategoryListComponent;

  id: number;

  customerOptions: Observable<CustomerModel[]>;

  customerSelected: CustomerModel;

  salesRepOptions: Observable<SalesRepModel[]>;

  salesRepSelected: SalesRepModel;

  order = new OrderModel();

  originalOrder: OrderModel;

  originalOrderItemsMap: Map<number, OrderItemModel> = new Map();

  originalSplitOrderItemsMap: Map<number, OrderItemModel> = new Map();

  customerChangeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  deliveryDetailsData: DeliveryDetailsData;

  productCategories: MappedProducts[];

  priorities = OrderPriorityDropDownConstant;

  orderItemMap: Map<number, OrderItemModel> = new Map();

  splitOrderItemMap: Map<number, OrderItemModel> = new Map();

  totalIncl = 0;

  totalExcl = 0;

  validAmountMap: Record<number, boolean> = {};

  productListBehaviourSubject: BehaviorSubject<Map<number, OrderItemModel>> = new BehaviorSubject<Map<number, OrderItemModel>>(null);

  validChangeBehaviourSubject: BehaviorSubject<{ productSkuId: number, valid: boolean }> = new BehaviorSubject<{ productSkuId: number, valid: boolean }>(null);

  customerDeliveryAddresses: CustomerDeliveryAddress[] = [];

  orderForm = this.formBuilder.group({
    id: [ { value: '', disabled: true } ],
    externalOrderNo: [ '' ],
    dueDate: [ '', Validators.required ],
    customer: [ '', Validators.required ],
    salesRep: [ '', Validators.required ],
    priority: [ '', Validators.required ],
    captureSupergroup: []
  });

  constructor(
    public readonly ovAutoService: OvAutoService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly orderService: OrderService,
    private readonly inventoryAccordionService: InventoryAccordionService<T>,
    private formBuilder: FormBuilder,
  ) {
    this.route.params.subscribe(param => {
      this.id = parseInt(param[ 'id' ], 10);
      // disabled change customer on edit order
      if (this.id) {
        this.orderForm.get('customer').disable();
      }
    });

    this.customerOptions = this.orderForm.get('customer').valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      filter(customer => !!customer),
      switchMap(customer => {
        return from(this.orderService.listCustomersByName(customer));
      })
    );

    this.salesRepOptions = this.orderForm.get('salesRep').valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      filter(salesRep => !!salesRep),
      switchMap(salesRep => {
        return from(this.orderService.listSalesRepByName(salesRep));
      })
    );
  }

  async ngOnInit(): Promise<void> {
    this.productCategories = await this.inventoryAccordionService.listProductCategories(null);

    if (this.id) {
      this.order = await this.orderService.loadOrder(this.id);

      this.originalOrder = Object.assign({}, this.order);

      await this.prepareUpdateOrder();
    }

    this.validChangeBehaviourSubject.subscribe(res => {
      if (res) {
        this.validChange(res.productSkuId, res.valid);
      }
    })

    this.productListBehaviourSubject.subscribe(res => {
      console.log('PRODUCT LIST BEHAVIOUR SUBJECT: ', res)
      if (res) {
        this.productList(res);
      }
    })

    // This is reading a variable from a child component and listening to any changes to it.
    this.productCategoryList.orderItemMapSubject.subscribe((orderItemMap: Map<number, OrderItemModel>[]) => {
      const [normalOrderedItems, splitOrderedItems] = orderItemMap;
      this.productList(normalOrderedItems, splitOrderedItems);
    });
  }

  ngOnDestroy(): void {
    this.validChangeBehaviourSubject.unsubscribe();
    this.productListBehaviourSubject.unsubscribe();
  }

  onSupergroupCaptureChange() {
    const curSupergroupValue = this.orderForm.get('captureSupergroup').value;
    this.orderForm.patchValue({ 'captureSupergroup': !curSupergroupValue });
  }

  async prepareUpdateOrder() {
    this.customerSelected = this.order.customer;
    this.salesRepSelected = this.order.salesRep;

    this.orderForm.patchValue({
      id: this.order.id,
      dueDate: this.order.orderDate,
      customer: this.customerSelected.name,
      externalOrderNo: this.order.externalOrderNo,
      salesRep: this.salesRepSelected?.user ? `${this.salesRepSelected.user.firstName} ${this.salesRepSelected.user.lastName}` : null,
      priority: this.order.priority,
      captureSupergroup: this.order.captureSupergroup,
    });

    this.deliveryDetailsData = {
      deliveryAddress: this.order.deliveryAddress,
      specialInstructions: this.order.specialInstructions,
    }

    this.customerDeliveryAddresses = await this.orderService.fetchDeliveryAddresses(this.customerSelected.id)

    this.prepareOrderItems();
  }

  async archiveOrder() {
    swal.fire({
      title: "Are you sure?",
      text: "This order will be archived!",
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.ovAutoService.delete(OrderModel, this.order.id).then(() => {
          swal.fire({ title: 'Success', text: 'Order successfully archived' })
            .then(() => {
              this.back();
            })
        })
      }
    })
  }

  //TODO Make sure customer is selected in the update part
  async selectCustomer(customer: CustomerModel) {
    this.customerSelected = customer;

    // TODO place in own method
    this.customerDeliveryAddresses = await this.orderService.fetchDeliveryAddresses(this.customerSelected.id)

    // This is a separate sync of data that is still maintained.
    this.order.deliveryAddress = this.customerSelected.defaultDeliveryAddress;

    this.order.customer = customer;

    this.customerChangeSubject.next(customer.id);

    this.deliveryDetailsData = {
      deliveryAddress: this.order.deliveryAddress,
      specialInstructions: this.order.specialInstructions,
    }
  }

  selectSalesRep(rep: SalesRepModel) {
    this.salesRepSelected = rep;
  }

  async back() {
    await this.router.navigate([ '/orders' ]);
  }

  getTotalIncl(): string {
    return this.totalIncl.toFixed(2);
  }

  getTotalExcl(): string {
    return this.totalExcl.toFixed(2);
  }

  getVatDiff(): string {
    return (this.totalIncl - this.totalExcl).toFixed(2);
  }

  getVat(): string {
    if (!this.totalIncl) {
      return '0.00';
    }
    return ((this.totalIncl - this.totalExcl) / this.totalIncl * 100).toFixed(2);
  }

  processOrderDetails(): boolean {
    if (Object.values(this.validAmountMap).some(value => value === false)) {
      swal.fire({ title: 'Please make sure all amounts are valid', type: 'warning' });
      return false;
    }

    if (this.orderForm.invalid) {
      swal.fire({ title: 'Please fill in all the required fields', type: 'warning' });
      return false;
    }

    if (!this.orderItemMap.size) {
      swal.fire({ title: 'Please add items to this order', type: 'warning' });
      return false;
    }

    const totalQuantity = Array.from(this.orderItemMap.values()).some(foi => {
      return foi.quantity > 0;
    })

    if (!totalQuantity) {
      swal.fire({ title: 'Please ensure that at least one product has a quantity greater an zero', type: 'warning' });
      return false;
    }

    if (!this.validateDeliveryAddress(this.order.deliveryAddress)) {
      swal.fire({ title: 'Please ensure delivery address is set', type: 'warning' });
      return false;
    }

    this.order.salesRep = this.salesRepSelected;
    this.order.salesRepId = this.salesRepSelected.id;
    this.order.externalOrderNo = this.orderForm.get('externalOrderNo').value;
    this.order.priority = this.orderForm.get('priority').value;
    this.order.orderDate = new Date(this.orderForm.get('dueDate').value).toISOString();
    this.order.captureSupergroup = this.orderForm.get('captureSupergroup').value;

    if (this.id) {
      this.order.id = this.id;
    } else {
      this.order.orderCode = 'Default Order';
      this.order.fulfilmentDate = this.order.orderDate;
    }

    return true;
  }

  async save() {
    const validationResult = this.processOrderDetails();

    if (!validationResult) return;

    try {
      if (this.order.id) {
        let updatedOrderItemMap = new Map<number, OrderItemModel>();
        let updatedSplitOrderItemMap = new Map<number, OrderItemModel>();

        Array.from(this.orderItemMap.keys()).forEach(key_productSkuId => {
          // At the point of saving, reduce the amount of normal item by the amount of split item
          if (this.splitOrderItemMap.has(key_productSkuId)) {
            const orderItemToAdjust = this.orderItemMap.get(key_productSkuId);
            orderItemToAdjust.quantity -= this.splitOrderItemMap.get(key_productSkuId).quantity;
          }
        })

        // removing productSku from all map entries
        for (const [key, value] of this.orderItemMap.entries()) {
          const { productSku, ...rest } = value;
          updatedOrderItemMap.set(key, rest as OrderItemModel);
        }

        for (const [key, value] of this.splitOrderItemMap.entries()) {
          const { productSku, ...rest } = value;
          updatedSplitOrderItemMap.set(key, rest as OrderItemModel);
        }

        await this.orderService.updateOrder(
          this.order, this.originalOrder,
          updatedOrderItemMap, this.originalOrderItemsMap,
          updatedSplitOrderItemMap, this.originalSplitOrderItemsMap
          );
      } else {
        Array.from(this.orderItemMap.keys()).forEach(key_productSkuId => {
          // At the point of saving, reduce the amount of normal item by the amount of split item
          if (this.splitOrderItemMap.has(key_productSkuId)) {
            const orderItemToAdjust = this.orderItemMap.get(key_productSkuId);
            orderItemToAdjust.quantity -= this.splitOrderItemMap.get(key_productSkuId).quantity;
          }
        })

        const normalOrderItems = Array.from(this.orderItemMap.values());
        const splitOrderItems = Array.from(this.splitOrderItemMap.values());
        const allOrderItems = [...normalOrderItems, ...splitOrderItems]

        await this.orderService.createOrder(this.order, allOrderItems);
      }

      swal.fire({ title: 'Successfully saved order', type: 'success'}).then(() => this.back());
    } catch(e) {
      console.error(e);
      swal.fire({ title: 'Error occurred', text: e.message, type: 'error' });
    }
  }

  /**
   * Check if atleast one value is set in any of the address fields.
   *
   * Good to know: Sage fails to sync order if no address is populated.
   *
   * @param address
   */
  validateDeliveryAddress(address: AddressModel): boolean {
    let valid = false;

    Object.keys(address).forEach(key => {
      if (address[key] !== null && address[key] !== undefined && address[key] !== '') {
        valid = true; // Return true if at least one property is set
      }
    })

    return valid;
  }

  prepareOrderItems(): void {
    const orderedItems = new Map();
    const splitOrderedItems = new Map();

    this.order.orderItems.forEach(item => {
      const clone: OrderItemModel = Object.assign({}, item)

      if (item.split) {
        splitOrderedItems.set(item.productSkuId, item);
        this.originalSplitOrderItemsMap.set(clone.productSkuId, clone);
      } else {
        orderedItems.set(item.productSkuId, item);
        this.originalOrderItemsMap.set(clone.productSkuId, clone);
      }
    })

    this.productList(orderedItems, splitOrderedItems);
  }

  productList(orderItems: Map<number, OrderItemModel>, splitOrderItemMap?: Map<number, OrderItemModel>) {
    this.totalExcl = 0;
    this.totalIncl = 0;

    this.orderItemMap = orderItems;

    if (splitOrderItemMap) {
      this.splitOrderItemMap = splitOrderItemMap;
    }

    orderItems.forEach((item: OrderItemModel) => {
      this.totalIncl += item.quantity * item.unitPriceIncl;
      this.totalExcl += item.quantity * item.unitPriceExcl;
    });
  }

  adjustDeliveryDetails(output: DeliveryOutput) {
    this.order.deliveryAddress = output.deliveryInstructions;

    this.order.specialInstructions = output.specialInstructions;

    this.deliveryDetailsData = {
      deliveryAddress: output.deliveryInstructions,
      specialInstructions: output.specialInstructions,
    }
  }

  validChange(productSkuId: number, valid: boolean) {
    this.validAmountMap[productSkuId] = valid;
  }
}
