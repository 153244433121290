import { Injectable } from '@angular/core';
import { CustomerModel, ProductCategory, ProductSkuModel } from '@ov-suite/models-admin';
import { OrderItemModel,  ReturnItem } from '@ov-suite/models-order';
import { OvAutoService } from "@ov-suite/services";

export type ProductSkuDisplay = {
  id: number;
  name: string;
  sku: string;
  quantityFree: number;
  quantityOnHand: number;
  exclPrice: number;
  inclPrice: number;
  increment: number;
  category: ProductCategory;
  model: ProductSkuModel;
  quantity?: number;
};

@Injectable()
export class ProductSkuService<T extends OrderItemModel | ReturnItem> {
  constructor(private readonly ovAutoService: OvAutoService) {}

  async listCategoryProducts(customer: CustomerModel): Promise<ProductSkuDisplay[]> {
    let defaultPriceListId;

    if (customer?.defaultPriceListId) {
      defaultPriceListId = customer.defaultPriceListId;
    }

    const productSkuList = await this.ovAutoService.list({
      entity: ProductSkuModel,
      limit: 10000,
      keys: [
        'id',
        'name',
        'sku',
        'category',
        'category.id',
        'category.path',
        'category.name',
        'quantityFree',
        'quantityOnHand',
        'inverseProductSkuConfigurations.id',
        'prices.exclPrice',
        'prices.inclPrice',
        'prices.priceList.id',
        'prices.priceList.default'
      ],
      query: {
        'category.id': [
          { operator: 'IS NOT', value: null },
        ],
        'integrationId': [
          { operator: 'IS NOT', value: null },
        ]
      },
    }).then(res => res.data);

    // Map return Object
    return productSkuList.map(product => ({
      id: product.id,
      name: product.name,
      sku: product.sku,
      quantityFree: product.quantityFree,
      quantityOnHand: product.quantityOnHand,
      exclPrice: product.getPriceExcl(defaultPriceListId),
      inclPrice: product.getPriceIncl(defaultPriceListId),
      increment: 1,
      category: product.category,
      model: product,
      quantity: 0,
    }));
  }

  async getSelectedCustomer(id: number): Promise<CustomerModel> {
    return this.ovAutoService.get({
      entity: CustomerModel,
      id: id,
    });
  }
}
