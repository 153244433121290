import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SalesConstraintRoutes } from './sales-constaint.routing';
import { SalesConstraintListComponent } from './list/sales-constraint-list.component';
import { UiModule } from '@ov-suite/ui';
import { SalesConstraintAddOrEditComponent } from './add-or-edit/sales-constraint-add-or-edit.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { AddConstraintModalComponent } from './add-or-edit/constraint-modal/add-constraint-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddProductSkuModalComponent } from './add-or-edit/add-product-sku-modal/add-product-sku-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(SalesConstraintRoutes),
    UiModule,
    MatCardModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatCheckboxModule,
    FormsModule,
    MatAutocompleteModule,
  ],
  declarations: [
    SalesConstraintListComponent,
    SalesConstraintAddOrEditComponent,
    AddConstraintModalComponent,
    AddProductSkuModalComponent,
  ],
  providers: [],
})
export class SalesConstraintModule {}
