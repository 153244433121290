import { ErrorHandler, NgModule } from '@angular/core';
import { AdminLayoutModule, GraphQLModule, UiModule } from '@ov-suite/ui';
import { JwtInterceptor } from '@ov-suite/authguard-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DomainService, SharedModule } from '@ov-suite/helpers-angular';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';
import { environment } from '@ov-suite/helpers-shared/lib/environments/environment';
import { getUrl } from '@ov-suite/helpers-angular/lib/get-url.helper';
import { GlobalErrorHandler } from '@ov-suite/helpers-angular/lib/error.handler';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { angularRoutes, sidebarRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { OrderService } from './services/order/order.service';

@NgModule({
  declarations: [AppComponent, AuthLayoutComponent],
  imports: [
    AdminLayoutModule.forRoot({
      projectName: 'Order Link',
      link: 'order',
      sidebarRoutes,
      angularRoutes,
    }),
    UiModule.forRoot({
      apiUrl: getUrl('api'),
      imageUploadUrl: environment.other.awsFileServiceApi,
    }),
    SharedModule,
    GraphQLModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    AuthenticationService,
    DomainService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    OrderService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
