import { Component, ElementRef, Inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { OrderModel, OrderCommentModel } from '@ov-suite/models-order';
import { getCreate } from '@ov-suite/graphql-helpers';
import moment from 'moment';

@Component({
  selector: 'ov-suite-comment-modal',
  templateUrl: './comment-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./comment-modal.component.scss'],
})
export class CommentModalComponent implements OnInit {
  @ViewChild('commentInput') commentInput: ElementRef;

  @ViewChild('scroll') private readonly scrollContainer: ElementRef;

  @Input() public description = '';

  @Input() order: OrderModel;

  comment = '';

  highlight: number;

  inputDisabled = false;

  danger = false;

  comments: OrderCommentModel[] = [];

  loading = true;

  target: OrderCommentModel;

  constructor(
    public ovAutoService: OvAutoService,
    protected modalService: NgbModal,
  ) {}

  scrollToBottom(): void {
    try {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  ngOnInit(): void {
    this.getData();
    this.scrollToBottom();
  }

  navigateBack() {
    window.history.back();
  }

  getPrettyDate(date) {
    return `${moment(date).format('h:mm a')} ${moment(date).format('MMM DD')}`;
  }

  commentChanged() {
    this.danger = this.comment === '';
  }

  saveComment(payload: OrderCommentModel) {
    this.inputDisabled = true;
    if (this.comment) {
      this.ovAutoService
        .create(OrderCommentModel, this.getPayload(payload))
        .then(() => {
          this.getData();
          this.cancel();
        })
        .finally(() => {
          this.inputDisabled = false;
        });
    } else {
      this.danger = true;
      this.inputDisabled = false;
    }
  }

  getPayload(comment: OrderCommentModel) {
    const orderComment = new OrderCommentModel();
    orderComment.message = this.comment;
    orderComment.order = this.order;
    orderComment.timestamp = moment().format();
    orderComment.target = comment;
    return getCreate(orderComment);
  }

  getData(): void {
    this.ovAutoService
      .list({
        entity: OrderCommentModel,
        limit: 1000,
        query: { orderId: [this.order.id] },
      })
      .then(result => {
        this.comments = result.data;
        this.loading = false;
        this.scrollToBottom();
      })
      .catch(err => console.error(err));
  }

  close = () => {
    this.modalService.dismissAll();
  };

  cancel() {
    this.target = null;
    this.highlight = null;
    this.comment = '';
  }

  reply(comment: OrderCommentModel, i: number) {
    this.highlight = i;
    this.target = comment;
  }
}
