import { Component, HostListener, OnInit } from '@angular/core';
import { FilterTab } from '@ov-suite/ui';
import { OvAutoService } from '@ov-suite/services';
import { ColumnData, QueryParams } from '@ov-suite/helpers-shared';
import { ReturnsDetailsModalComponent } from '../returns-details-modal/returns-details-modal.component';
import { ReturnModel } from '@ov-suite/models-order';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'ov-suite-orders-list',
  templateUrl: './returns-list.component.html',
  styleUrls: ['./returns-list.component.scss'],
})
export class ReturnsListComponent implements OnInit {

  screenHeight: number;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
  }

  formClass = ReturnModel;

  columns: ColumnData<ReturnModel>[] = [
    {
      keys: ['id'],
      title: 'Returns Order ID',
      type: 'other',
      disableFiltering: true,
      action: returnModel => returnModel.id.toString().padStart(4, '0'),
      rowItemAction: (item, { ngbModal }) => {
        const modalRef = ngbModal.open(ReturnsDetailsModalComponent, { size: 'xl' });
        modalRef.componentInstance.return = item;
      },
    },
    {
      key: 'createDate',
      title: 'Date Created',
      type: 'date',
      format: 'DD/MM/YYYY hh:mm:ss',
      disableSorting: true,
      disableFiltering: true,
    },
    {
      title: 'Customer Code',
      type: 'other',
      keys: ['customer.customerCode'],
      action: returnModel => returnModel?.customer?.customerCode,
    },
    {
      title: 'Customer Name',
      type: 'other',
      keys: ['customer.name'],
      action: returnModel => returnModel?.customer?.name,
    },
    {
      title: 'Return Amount',
      type: 'other',
      keys: ['returnItems', 'returnItems.price'],
      action: col => col.returnItems?.reduce((prev, item) => {
        prev += item.unitPriceIncl * item.quantity
        return prev;
      }, 0).toFixed(2),
      disableSorting: true,
      disableFiltering: true,
    },
    {
      title: 'Products Returned',
      type: 'other',
      keys: ['returnItems'],
      action: col => col.returnItems?.length.toString(),
      disableSorting: true,
      disableFiltering: true,
    },
    // {
    //   title: 'Status',
    //   filterKey: 'status',
    //   disableFiltering: true,
    //   keys: ['priority', 'orderItems'], // using orderItems for the popup
    //   type: 'other',
    //   action: returnModel => `<span [class]="'badge '+ ${ReturnPriorityConstant[returnModel?.status]?.color}">
    //         ${ReturnPriorityConstant[returnModel?.status]?.text} </span>`,
    // },
    {
      title: 'Actions',
      type: 'buttons',
      hideColumnKey: 'actions',
      disableSorting: true,
      disableFiltering: true,
      keys: ['customer.id'],
      buttons: [
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'edit-2',
          action: async (item, apiService) => {
            await this.router.navigate(['returns/edit', item.id] )
          },
        },
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'file-1',
          actionType: 'delete',
          action: (item, { columnAction }) => columnAction()
        },
      ],
    },
  ];

  filter: Record<string, QueryParams[]> = null;

  currentData: ReturnModel[] = [];

  selected: ReturnModel[];

  bulkChanges: Record<string, unknown>;

  reFetch = 0;

  dropdownData: Record<string, unknown>;

  tabs: FilterTab[] = [
    { title: 'All', value: null },
    { title: 'Drafts', value: 'drafts' },
    { title: 'In-Progress', value: 'inProgress' },
    { title: 'Completed', value: 'completed' },
  ];

  constructor(public readonly ovAutoService: OvAutoService,
              private router: Router) {}

  ngOnInit(): void {
    // editable table, dropdowns information
    this.getData();
  }

  getData() {
    this.ovAutoService
      .list({
        entity: ReturnModel,
        orderDirection: 'ASC',
        orderColumn: 'id'
      })
      .then(result => {
        this.currentData = result.data;
      })
      .catch(err => console.error(err));
  }

  add(){
    this.router.navigate(['returns', 'add']).then();
  }

  setFilter(tab: FilterTab) {
    this.filter = tab.value ? { s: [tab.value] } : null;
  }
}
