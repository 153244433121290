<mat-card>
  <mat-card-title class="add-edit-header">Delivery Details</mat-card-title>
  <mat-card-content>
    <mat-form-field appearance="fill">
      <mat-label>Select Delivery Address</mat-label>
      <mat-select (selectionChange)="onDeliveryAddressSelected($event)">
        <mat-option disabled>Select Delivery Address</mat-option>
        <mat-option *ngFor="let deliveryAddress of deliveryAddresses"
                    [value]="deliveryAddress"
        >
          {{ deliveryAddress.getOneLiner() }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <form [formGroup]="deliveryForm">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="fill">
            <mat-label>Street</mat-label>
            <input matInput
                   id="line2"
                   name="line2"
                   formControlName="line2"
                   placeholder="Street Name"
            >
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="fill">
            <mat-label>Street Number</mat-label>
            <input matInput
                   id="line1"
                   name="line1"
                   formControlName="line1"
                   placeholder="Street Number"
            >
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="fill">
            <mat-label>Postal Code</mat-label>
            <input matInput
                   id="postalCode"
                   name="postalCode"
                   formControlName="postalCode"
                   placeholder="Postal Code"
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill">
            <mat-label>Suburb</mat-label>
            <input matInput
                   id="line3"
                   name="line3"
                   formControlName="line3"
                   placeholder="Suburb"
            >
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill">
            <mat-label>City/Town</mat-label>
            <input matInput
                   name="line4"
                   id="line4"
                   formControlName="line4"
                   placeholder="City/Town"
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill">
            <mat-label>Province</mat-label>
            <input matInput
                   id="line5"
                   name="line5"
                   formControlName="line5"
                   placeholder="Province"
            >
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill">
            <mat-label>Country</mat-label>
            <input matInput
                   id="line6"
                   name="line6"
                   formControlName="line6"
                   placeholder="Country"
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill">
            <mat-label>Special Requirements</mat-label>
            <textarea matInput
                      id="specialInstructions"
                      name="specialInstructions"
                      formControlName="specialInstructions"
                      placeholder="Special Instructions"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
