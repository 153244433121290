<mat-card class="mat-elevation-z0">
  <ng-container *ngIf="order.integrationException; else noException">
    <ng-container *ngTemplateOutlet="unsuccessful"></ng-container>
  </ng-container>
  <ng-template #noException>
      <ng-container *ngIf="!order.syncDate && !order.integrationId">
        <ng-container *ngTemplateOutlet="error"></ng-container>
      </ng-container>
      <ng-container *ngIf="order.integrationId && !order.syncDate">
        <ng-container *ngTemplateOutlet="successful"></ng-container>
      </ng-container>
      <ng-container *ngIf="!order.integrationId && order.syncDate">
        <ng-container *ngTemplateOutlet="notYetSynced"></ng-container>
      </ng-container>
  </ng-template>
</mat-card>

<ng-template #successful>
  <mat-card-content>
    <mat-card-content class="exception-success-text">
      This order has successfully synced with Sage Evolution.
    </mat-card-content>
  </mat-card-content>
</ng-template>

<ng-template #notYetSynced>
  <mat-card-content class="exception-warning-text">
    This order has not yet been synced. Please wait a few minutes and ensure order is not opened in Sage Evolution
  </mat-card-content>
</ng-template>

<ng-template #unsuccessful>
  <mat-card-content>
    <mat-card class="mat-elevation-z0">
      <mat-card-title class="exception-warning-title">Order has not synced with the following exception:</mat-card-title>
      <mat-card-content class="exception-error-text">{{ order.integrationException }}</mat-card-content>
    </mat-card>

  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button color="warn" (click)="resetOrder()">Reset</button>
  </mat-card-actions>
</ng-template>

<ng-template #error>
  <mat-card-content>
    <mat-card class="mat-elevation-z0">
      <mat-card-title class="exception-warning-title">Order has not synced:</mat-card-title>
      <mat-card-content class="exception-error-text">An Exception has occurred. Please reset to try and re-sync order again.</mat-card-content>
    </mat-card>

  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button color="warn" (click)="resetOrder()">Reset</button>
  </mat-card-actions>
</ng-template>
