import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddressModel } from '@ov-suite/models-shared';
import { DeliveryDetailsData } from '../add-edit/add-edit-order.component';
import { CustomerDeliveryAddress } from '@ov-suite/models-admin';
import { MatSelectChange } from '@angular/material/select';

export type DeliveryOutput = {
  deliveryInstructions: AddressModel,
  specialInstructions: string
}

@Component({
  selector: 'ov-suite-order-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss'],
})
export class DeliveryDetailsComponent implements OnInit {
  @Input() deliveryAddresses: CustomerDeliveryAddress[] = [];

  @Output() deliveryDetailsOutput = new EventEmitter<DeliveryOutput>();

  @Input() set deliveryData(deliveryData: DeliveryDetailsData) {
    if (deliveryData) {
      this.deliveryForm.patchValue({
        line1: deliveryData.deliveryAddress?.line1,
        line2: deliveryData.deliveryAddress?.line2,
        line3: deliveryData.deliveryAddress?.line3,
        line4: deliveryData.deliveryAddress?.line4,
        line5: deliveryData.deliveryAddress?.line5,
        line6: deliveryData.deliveryAddress?.line6,
        postalCode: deliveryData.deliveryAddress?.postalCode,
        specialInstructions: deliveryData.specialInstructions,
      })
    }
  }

  deliveryForm: FormGroup = this.formBuilder.group({
    line1: [''],
    line2: [''],
    line3: [''],
    line4: [''],
    line5: [''],
    line6: [''],
    postalCode: [''],
    specialInstructions: [''],
  })

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.deliveryForm.valueChanges.subscribe(form => {
      const { line1, line2, line3, line4, line5, line6, postalCode, specialInstructions } = form;

      const addressDetails = new AddressModel();

      addressDetails.line1 = line1;
      addressDetails.line2 = line2;
      addressDetails.line3 = line3;
      addressDetails.line4 = line4;
      addressDetails.line5 = line5;
      addressDetails.line6 = line6;
      addressDetails.postalCode = postalCode;

      const output: DeliveryOutput = {
        deliveryInstructions: addressDetails,
        specialInstructions: specialInstructions,
      }

      this.deliveryDetailsOutput.emit(output);
    })
  }

  onDeliveryAddressSelected(selectedDeliveryAddress: MatSelectChange) {
    const deliveryAddress = selectedDeliveryAddress.value;

    this.deliveryForm.patchValue({
      line1: deliveryAddress.line1,
      line2: deliveryAddress.line2,
      line3: deliveryAddress.line3,
      line4: deliveryAddress.line4,
      line5: deliveryAddress.line5,
      line6: deliveryAddress.line6,
      postalCode: deliveryAddress.postalCode,
    })
  }
}
