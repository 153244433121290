import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProductSkuModel } from '@ov-suite/models-admin';
import { getQuantities, Quantities } from './constraints.helper';

@Component({
  selector: 'ov-suite-quantity-picker',
  templateUrl: './quantity-picker.component.html',
  styleUrls: ['./quantity-picker.component.scss'],
})
export class QuantityPickerComponent {
  _value: number;

  @Input() productSku: ProductSkuModel;

  quantities = new Quantities(0);

  @Input()
  set value(input: number) {
    this._value = input;
    this.quantities = getQuantities(this.productSku, input);
  }

  get value(): number {
    return this._value;
  }

  @Output() onChange = new EventEmitter<Quantities>();

  changeAmount(amount: number): void {
    this.value = amount;
    this.onChange.emit(this.quantities);
  }
}
