<div class="main-content">

  <div class="top-action">
    <button mat-stroked-button color="accent" (click)="back()">Cancel</button>
    <div class="action-button-container">
      <!-- For some reason archive has not been implemented as yet -->
      <button mat-stroked-button color="warn" [disabled]="!returnId" (click)="archiveReturn()">Archive</button>
      <button mat-stroked-button color="primary" (click)="save()">Save</button>
    </div>
  </div>

  <div class="main-container-no-table">
    <div class="page-title">
        <span class="page-title-text">
          {{ returnId ? 'Update Return' : 'Create Return' }}
        </span>
    </div>

    <div class="return-details row">
      <mat-card [ngClass]="customerSelected ? 'col-md-9' : 'col-md-12'" class="return-details-card">
        <mat-card-title class="add-edit-header">Return Order Details</mat-card-title>

        <mat-card-content>
          <form [formGroup]="returnForm">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Return Order ID</mat-label>
                  <input matInput
                         formControlName="id"
                         name="returnId"
                         id="returnId"
                  >
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Customer</mat-label>
                  <input matInput
                         formControlName="customer"
                         id="customer"
                         name="customer"
                         [matAutocomplete]="auto1"
                  >
                  <mat-autocomplete #auto1="matAutocomplete">
                    <mat-option *ngFor="let customer of customerOptions | async"
                                [value]="customer.name"
                                (onSelectionChange)="selectCustomer(customer)"
                    >
                      {{ customer.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-card>

      <ov-suite-customer-details-edit
        class="col-md-3"
        *ngIf="customerSelected"
        [selectedCustomer]="customerSelected"
      ></ov-suite-customer-details-edit>
    </div>


    <br>

    <mat-card>
      <mat-card-title class="add-edit-header">
        <div class="return-details-items">
          <div class="add-edit-header">Returns</div>
          <div class="return-total-card">
            <span class="return-total-label">Total:</span>
            <span class="return-total-value">
              R {{ getTotalIncl() }}
            </span>
          </div>
        </div>
      </mat-card-title>

      <br>

      <mat-card-content>
        <ov-suite-accordion-category [type]="'return'"
                                     [productCategories]="productCategories"
                                     [productItemMap]="returnItemMap"
                                     (productListEmitter)="productList($event)"
        ></ov-suite-accordion-category>
      </mat-card-content>

      <br>

      <mat-card-content>
        <div class="orderSummary">
          <table>
            <tr>
              <td class="orders-total-label">Sub Total:</td>
              <td class="orders-total-value">R {{ getTotalExcl() }}</td>
            </tr><tr>
            <td class="orders-total-label">VAT: </td>
            <td class="orders-total-value">{{ getVat() }}%</td>
          </tr><tr>
            <td class="orders-total-label">Vat Amount: </td>
            <td class="orders-total-value">R {{ getVatDiff() }}</td>
          </tr><tr>
            <td class="orders-total-label total">Total Amount: </td>
            <td class="orders-total-value pull-right sales-contrast">R {{ getTotalIncl() }}</td>
          </tr>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
