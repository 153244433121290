<div class="main-content">
  <div class="main-container px-4 py-3">
    <span id="backButton" (click)="navigateBack()">
        </span>
    <div class="header">{{header}}</div>
    <br>
    <p>{{message}}</p>
    <br>
    <label>
      Admin Override PIN <span class="eye" (click)="togglePass = !togglePass"><i aria-hidden="true" [class]="togglePass ? 'fa fa-eye' : 'fa fa-eye-slash' "></i></span>
    </label>
    <div class="pin-input">
      <input #input1 maxlength="1" (keyup)="setFocus('input2')" class="pin-part" [type]="togglePass ? 'password' : 'text' " >
      <input #input2 maxlength="1" (keyup)="setFocus('input3')" class="pin-part" [type]="togglePass ? 'password' : 'text' ">
      <input #input3 maxlength="1" (keyup)="setFocus('input4')" class="pin-part" [type]="togglePass ? 'password' : 'text' ">
      <input #input4 maxlength="1" (keyup)="setFocus('input5')" class="pin-part" [type]="togglePass ? 'password' : 'text' ">
      <input #input5 maxlength="1" (keyup)="setFocus('input6')" class="pin-part" [type]="togglePass ? 'password' : 'text' ">
      <input #input6 maxlength="1" (keyup)="setFocus('overrideBtn')" class="pin-part" [type]="togglePass ? 'password' : 'text' " >
    </div>
    <br>
      <button class="btn btn-outline-default float-left" (click)="close()">
        <span class="float-left">
          Cancel
        </span>
        <span class="float-right">
          <i aria-hidden="true" class="x-dark"></i>
        </span>
      </button>
      <button class="btn btn-ovsuite-primary float-right" (click)="override()">
        <span class="float-left">
          Override
        </span>
        <span class="float-right">
          <i aria-hidden="true" class="check-1"></i>
        </span>
      </button>
  </div>
</div>
