import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderModel } from '@ov-suite/models-order';
import { OvAutoService } from '@ov-suite/services';
import { gql } from '@apollo/client';
import { firstValueFrom } from 'rxjs';
import swal from 'sweetalert2';
import { OrderService } from '../../../../services/order/order.service';

@Component({
  selector: 'ov-suite-integration-exception-dialog',
  templateUrl: './integration-exception-dialog.component.html',
  styleUrls: ['./integration-exception-dialog.component.css']
})
export class IntegrationExceptionDialogComponent {
  @Input() order: OrderModel;

  @Output() closeEmission = new EventEmitter<string>();

  constructor(private readonly ovAutoService: OvAutoService, private readonly orderService: OrderService) {}

  async resetOrder() {
    this.orderService.resetOrder(this.order).then(res => {
      if (res) {
        this.closeEmission.emit('Successfully reset order');
        return;
      }

      swal.fire({ title: 'Could not reset order. Please try again.', type: 'warning' });
    })
  }
}
