import { Routes } from '@angular/router';
import { AdminLayoutComponent, RouteInfo } from '@ov-suite/ui';
import { AuthGuard } from '@ov-suite/authguard-angular';

export const angularRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('@ov-suite/authguard-angular').then(m => m.UnauthorizedModule),
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'orders',
        loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersModule),
      },
      {
        path: 'returns',
        loadChildren: () => import('./modules/returns/returns.module').then(m => m.ReturnsModule),
      },
      {
        path: 'sales-rep',
        loadChildren: () => import('./modules/sales-rep/sales-rep.module').then(m => m.SalesRepModule),
      },
      {
        path: 'sales-rep-group',
        loadChildren: () => import('./modules/sales-rep-group/sales-rep-group.module').then(m => m.SalesRepGroupModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('@ov-suite/services-angular').then(m => m.NotificationsListModule),
      },
      {
        path: 'inventory',
        loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule),
      },
      {
        path: 'sales-constraint',
        loadChildren: () => import('./modules/sales-constraint/sales-constraint.module').then(m => m.SalesConstraintModule),
      },
    ],
  },
];

export const sidebarRoutes: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'dashboard',
    icontype: 'fa fa-pie-chart',
    type: 'link',
  },
  {
    path: '/',
    title: 'Order Management',
    type: 'sub',
    collapse: 'orders',
    icontype: 'fa fa-bars',
    children: [
      { path: 'orders', title: 'Orders', ab: 'OR' },
      { path: 'returns', title: 'Returns', ab: 'RE' },
    ],
  },
  {
    path: '/',
    title: 'Sales Rep Management',
    type: 'sub',
    collapse: 'order-setup',
    icontype: 'fa fa-plus-circle',
    children: [
      { path: 'sales-rep', title: 'Sales Reps', ab: 'SR' },
      { path: 'sales-rep-group', title: 'Sales Rep Groups', ab: 'SRG' },
    ],
  },
  {
    path: '/',
    title: 'Inventory',
    type: 'sub',
    collapse: 'inventory',
    icontype: 'fa fa-bar-chart',
    children: [
      { path: 'inventory', title: 'Inventory', ab: 'IN' },
      { path: 'sales-constraint', title: 'Sales Constraints', ab: 'SC' },
    ],
  },
];
