import { Component, Input, OnInit } from '@angular/core';
import { FieldMetadata, getFieldMetadata, OVTag, PageReturn } from '@ov-suite/ov-metadata';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { OrderOverride, ReturnItem, ReturnModel, Reason } from '@ov-suite/models-order';

@OVTag('order-details-modal', OrderOverride)
@Component({
  selector: 'ov-suite-returns-details-modal',
  templateUrl: './returns-details-modal.component.html',
})
export class ReturnsDetailsModalComponent implements OnInit {

  page = 0;

  pageSize = 10;

  @Input() return: ReturnModel;

  filter: Record<string, number[]>;

  data: ReturnItem[] = [];

  // Class - Required
  formClass = ReturnItem;

  metadata: FieldMetadata<ReturnItem>;

  dropdownData: Record<string, unknown>;

  constructor(public autoService: OvAutoService, protected modalService: NgbModal) {
    this.metadata = getFieldMetadata<ReturnItem>(this.formClass);
  }

  ngOnInit(): void {
    this.filter = { returnId: [this.return.id] };
    this.getData();
  }

  close = () => {
    this.modalService.dismissAll();
  };

  getData(): void{
    this.autoService
      .multipleFetch({
        returnItems: {
          entity: ReturnItem,
          type: 'list',
          offset: 0,
          orderDirection: 'ASC',
          orderColumn: 'id',
          keys: ['id', 'quantity', 'productSku', 'price', 'reason'],
          query: { returnId: [this.return?.id] },
        },
        reasons: {
          entity: Reason,
          type: 'list',
          keys: [
            'id',
            'reason',
          ],
        },
      })
      .then(response => {
        this.dropdownData = {reason: response.reasons.data};
        return this.data = response.returnItems.data;
      });
  }
}
