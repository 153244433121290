import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { UiModule } from "@ov-suite/ui";
import { CommentModalComponent } from "./comment-modal.component";

@NgModule({
  imports: [CommonModule,
    FormsModule,
    UiModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgbTooltipModule
  ],
  declarations: [CommentModalComponent],
  providers: [],
  exports: [CommentModalComponent]
})
export class CommentModalModule {}
