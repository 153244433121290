<div class="main-content">
  <ov-suite-create-or-edit
    [ovAutoService]="ovAutoService"
    [formClass]="entity"
    [title]="'Product SKU'"
    (save)="navigateBack()"
    (cancel)="navigateBack()"
  >
  </ov-suite-create-or-edit>
</div>
