import { Routes } from '@angular/router';
import { OrdersListComponent } from './list-orders/orders-list.component';
import { OrderAddEditComponent } from './add-edit/add-edit-order.component';
import { SplitDetailsListComponent } from './list-split-details/split-details-list.component';

export const OrdersRoutes: Routes = [
  {
    path: '',
    children: [
      {
        // data: { feature: { id: WarehousePermission.waves } },
        // canActivate: [AuthGuard],
        path: '',
        component: OrdersListComponent,
      },
      {
        path: 'add',
        component: OrderAddEditComponent,
      },
      {
        path: 'edit/:id',
        component: OrderAddEditComponent,
      },
      {
        path: 'split/:id',
        component: SplitDetailsListComponent,
      },
    ],
  },
];
