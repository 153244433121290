import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { OrderModel } from '@ov-suite/models-order';
import gql from 'graphql-tag';
import swal from 'sweetalert2';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'ov-suite-allocate-sales-rep-modal',
  templateUrl: './override-modal.component.html',
  styleUrls: ['./override-modal.component.scss'],
})
export class OverrideModalComponent {
  constructor(
    public ovAutoService: OvAutoService,
    protected modalService: NgbModal,
    private readonly apollo: Apollo,
  ) {}

  @Input() order: OrderModel;

  @Input() header: string;

  @Input() message: string;

  @Input() modal: unknown;

  togglePass = true;

  @ViewChild('input1') input1: ElementRef;

  @ViewChild('input2') input2: ElementRef;

  @ViewChild('input3') input3: ElementRef;

  @ViewChild('input4') input4: ElementRef;

  @ViewChild('input5') input5: ElementRef;

  @ViewChild('input6') input6: ElementRef;

  @ViewChild('overrideBtn') overrideBtn: ElementRef;

  setFocus(input: string) {
    if (input.startsWith('input')) {
      this[input].nativeElement.focus();
    } else {
      this.override();
    }
  }

  async override() {
    const valid = await this.validPin().then(resp => {
      return resp;
    });
    if (valid['validatePin']) {
      this.close();
      const modalRef = this.modalService.open(this.modal, { size: 'md' });
      modalRef.componentInstance.order = this.order;
      modalRef.componentInstance.overridden = true;
    } else {
      swal.fire({
        title: `Invalid PIN!`,
        showConfirmButton: true,
        type: 'error',
      });
    }
  }

  async validPin(): Promise<unknown> {
    const pin =
      this.input1.nativeElement.value +
      this.input2.nativeElement.value +
      this.input3.nativeElement.value +
      this.input4.nativeElement.value +
      this.input5.nativeElement.value +
      this.input6.nativeElement.value;
    const featureId = 302;
    return new Promise((resolve, reject) => {
      this.apollo
        .query({
          query: gql(`query validatePin($featureId: Int!, $pin: String!) {
        validatePin(featureId: $featureId, pin: $pin)
      }`),
          variables: { featureId, pin },
        })
        .subscribe(response => {
          resolve(response.data as boolean);
        }, reject);
    });
  }

  navigateBack() {
    window.history.back();
  }

  close = () => {
    this.modalService.dismissAll();
  };
}
