/* eslint-disable no-continue */
import { ProductSkuModel } from '@ov-suite/models-admin';

export class Quantities {
  low?: number;

  current = 0;

  high?: number;

  valid = false;

  constructor(current: number) {
    this.current = current;
  }
}

export function getQuantities(productSku: ProductSkuModel, currentValue: number): Quantities {
  if (!productSku.salesConstraint) {
    const output = new Quantities(currentValue);
    output.low = Math.max(0, currentValue - 1);
    output.high = currentValue + 1;
    output.valid = true;
    return output;
  }

  const output = new Quantities(currentValue);

  const { minimum } = productSku.salesConstraint;
  const { maximum } = productSku.salesConstraint;
  const { constraints } = productSku.salesConstraint;
  constraints.sort((a, b) => a.value - b.value);

  function greaterThan(a: number, b: number): number {
    return (a ?? 0) > (b ?? 0) ? a : b;
  }

  function lessThan(a: number, b: number): number {
    return (a ?? Infinity) < (b ?? Infinity) ? a : b;
  }

  for (const c of constraints) {
    const amount = c.value;
    const multiples = c.allowMultiples;

    if (multiples) {
      let div: number;
      if (minimum && minimum > currentValue) {
        div = Math.ceil(minimum / amount);
      } else {
        div = Math.floor(currentValue / amount);
      }

      const validAmount = div * amount;
      let low = 0;
      let high = 0;

      if (validAmount === currentValue) {
        output.valid = true;
        low = (div - 1) * amount;
        high = (div + 1) * amount;
      } else if (validAmount < currentValue) {
        low = validAmount;
        high = (div + 1) * amount;
      } else {
        low = (div - 1) * amount;
        high = validAmount;
      }

      if (!minimum || low >= minimum) {
        output.low = greaterThan(low, output.low);
      }
      if (minimum && (output.low === null || output.low === undefined)) {
        output.low ??= 0;
      }
      if (!maximum || high <= maximum) {
        output.high = lessThan(high, output.high);
      }
    } else {
      if (minimum && amount < minimum) {
        continue;
      }
      if (maximum && amount > maximum) {
        continue;
      }
      if (!output.valid) {
        output.valid = amount === currentValue;
        if (currentValue === 0) {
          output.valid = true;
        }
      }
      if (amount < currentValue) {
        output.low = greaterThan(amount, output.low);
      }
      if (amount > currentValue) {
        output.high = lessThan(amount, output.high);
      }
    }

    if (output.high && maximum && output.high > maximum) {
      output.high = maximum;
    }

    if (minimum && !multiples && amount < minimum) {
      continue;
    }
    if (maximum && amount > maximum) {
      break;
    }
  }

  if (output.current === 0) {
    output.valid = true;
  }

  return output;
}
