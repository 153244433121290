import { NgModule } from '@angular/core';
import { UiModule } from '@ov-suite/ui';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ReturnsListComponent } from './list-returns/returns-list.component';
import { ReturnsRoutes } from './returns.routing';
import { ReturnsDetailsModalComponent } from './returns-details-modal/returns-details-modal.component';
import { ReturnAddEditComponent } from "./add-edit/add-edit-return.component";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { CustomerDetailsBarModule } from "../customer-details-bar/customer-details-bar.module";
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CustomerDetailsEditModule } from '../customer-details-edit/customer-details-edit.module';

@NgModule({
  imports: [ CommonModule,
    RouterModule.forChild(ReturnsRoutes),
    FormsModule,
    UiModule,
    NgbDropdownModule,
    LeafletModule,
    NgbTooltipModule,
    MatSlideToggleModule,
    CustomerDetailsBarModule, MatButtonModule, MatCardModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, CustomerDetailsEditModule
  ],
  declarations: [ReturnsListComponent,
    ReturnsDetailsModalComponent,
    ReturnAddEditComponent],
  providers: [],
})
export class ReturnsModule {}
