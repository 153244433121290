<div class="main-content" [ngStyle] = "{'height.px': screenHeight - 1}">
  <ov-suite-filter-tabs (selectedTab)="setFilter($event)" [tabs]="tabs"> </ov-suite-filter-tabs>
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'Returns List'"
      [formClass]="formClass"
      [overrideColumnData]="columns"
      [hasBulkUpload]="false"
      [showTopBar]="true"
      [selectableRows]="true"
      [editableRows]="true"
      [hideAddButton]="false"
      [excludeColumns]="1"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
