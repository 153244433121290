import { Component } from '@angular/core';
import { environment } from '@ov-suite/helpers-shared/lib/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'OrderLink';

  authCheck = { complete: false };

  idmLinkUrl = environment.webUrl.idm;

  dev = !environment.production;
}
