import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OvAutoService } from '@ov-suite/services';
import { FieldMetadata, GenericHierarchy } from '@ov-suite/ov-metadata';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constraint, ProductSkuModel, SalesConstraintModel } from '@ov-suite/models-admin';
import { FormBuilder, FormControl } from '@angular/forms';
import { ColumnData } from '@ov-suite/helpers-shared';
import { MatDialog } from '@angular/material/dialog';
import { AddConstraintModalComponent } from './constraint-modal/add-constraint-modal.component';
import { MatCustomDataSource } from '@ov-suite/ui';
import { AddProductSkuModalComponent } from './add-product-sku-modal/add-product-sku-modal.component';
import { getCreate, getUpdate } from '@ov-suite/graphql-helpers';

interface DataSources<T extends GenericHierarchy = { id: number | string }> {
  [key: string]: T[] | OvAutoService | unknown;
}

@Component({
  selector: 'ov-suite-sales-constraint-add-edit',
  templateUrl: './sales-constraint-add-or-edit.component.html',
  styleUrls: ['./sales-constraint-add-or-edit.component.scss'],
})
export class SalesConstraintAddOrEditComponent implements OnInit {
  formClass = SalesConstraintModel;

  metadata: FieldMetadata<SalesConstraintModel>;

  title = 'Sales Constraint';

  formGroup = this.formBuilder.group({
    name: new FormControl(),
    minimum: new FormControl(),
    maximum: new FormControl(),
  });
  constraintsDataSource = new MatCustomDataSource<Constraint>([]);

  constraintColumnData: ColumnData<Constraint>[] = [
    {
      title: 'Amount',
      type: 'number',
      key: 'value',
      disableSorting: true,
    },
    {
      title: 'Allow Multiples',
      type: 'other',
      action: constraint => constraint.allowMultiples ? 'Yes' : 'No',
      keys: ['allowMultiples'],
      disableSorting: true,
    },
    {
      type: 'button-bar',
      title: 'Actions',
      disableSorting: true,
      buttons: [
        {
          title: 'Remove',
          actionType: 'custom',
          action: constraint => {
            const connect = this.constraintsDataSource.connect();
            connect.next(connect.value.filter(c => c !== constraint));
          }
        }
      ],
      keys: [],
    }
  ];

  productSkusDataSource = new MatCustomDataSource<ProductSkuModel>([]);

  productSkuColumnData: ColumnData<ProductSkuModel>[] = [
    {
      type: 'string',
      title: 'Name',
      key: 'name',
    },
    {
      type: 'string',
      title: 'SKU',
      key: 'sku',
    },
    {
      type: 'button-bar',
      title: 'Actions',
      buttons: [
        {
          title: 'Unassign',
          actionType: 'custom',
          action: productSku => {
            const connect = this.productSkusDataSource.connect();
            connect.next(connect.value.filter(p => p !== productSku));
          }
        }
      ],
      keys: [],
    }
  ]

  id: number;

  original: SalesConstraintModel;

  public dataSources: DataSources<GenericHierarchy> = {};

  constructor(
    private formBuilder: FormBuilder,
    public ovAutoService: OvAutoService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public route: ActivatedRoute,
    private readonly modalService: NgbModal,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.has('id')) {
        this.id = Number(params.get('id'));

        this.ovAutoService.get({
          entity: SalesConstraintModel,
          id: this.id,
          keys: ['id', 'name', 'minimum', 'maximum', 'constraints', 'productSkus', 'productSkus.id', 'productSkus.name', 'productSkus.sku'],
        }).then(salesConstraint => {
          this.original = salesConstraint;
          this.formGroup.get('name').setValue(salesConstraint.name);
          this.formGroup.get('minimum').setValue(salesConstraint.minimum);
          this.formGroup.get('maximum').setValue(salesConstraint.maximum);
          this.constraintsDataSource.connect().next(salesConstraint.constraints);
          this.productSkusDataSource.connect().next(salesConstraint.productSkus);
        })
      }
    })
  }

  async submit() {


    const salesConstraint = new SalesConstraintModel();
    salesConstraint.id = this.id;
    salesConstraint.name = this.formGroup.get('name').value;
    salesConstraint.minimum = this.formGroup.get('minimum').value;
    salesConstraint.maximum = this.formGroup.get('maximum').value;
    salesConstraint.constraints = this.constraintsDataSource.connect().value;
    salesConstraint.productSkus = this.productSkusDataSource.connect().value;

    if (this.id) {
      // Save
      this.ovAutoService.update({
        entity: SalesConstraintModel,
        item: getUpdate(salesConstraint, this.original),
      }).then(response => {
        console.log('update', { response });
        this.router.navigate(['sales-constraint'])
      })
      return;
    }

    this.ovAutoService.create({
      entity: SalesConstraintModel,
      item: getCreate(salesConstraint),
    }).then(response => {
      console.log('create', { response });
      this.router.navigate(['sales-constraint'])
    })
  }

  addConstraint() {
    this.dialog.open(AddConstraintModalComponent).afterClosed().subscribe((constraint: Constraint) => {
      if (constraint) {
        const connect = this.constraintsDataSource.connect();
        connect.next([...connect.value, constraint]);
      }
    })
  }

  addProductSku() {
    this.dialog.open(AddProductSkuModalComponent).afterClosed().subscribe((productSku: ProductSkuModel) => {
      if (productSku) {
        const connect = this.productSkusDataSource.connect();
        if (!connect.value.find(p => p.id === productSku.id)) {
          connect.next([...connect.value, productSku]);
        }
      }
    })
  }
}
