import { Routes } from '@angular/router';
import { ReturnsListComponent } from './list-returns/returns-list.component';
import { ReturnAddEditComponent } from "./add-edit/add-edit-return.component";

export const ReturnsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        // data: { feature: { id: WarehousePermission.waves } },
        // canActivate: [AuthGuard],
        path: '',
        component: ReturnsListComponent,
      },
      {
        path: 'add',
        component: ReturnAddEditComponent
      },
      {
        path: 'edit/:id',
        component: ReturnAddEditComponent
      },
    ],
  },
];
