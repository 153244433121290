<h1 mat-dialog-title>Assign to Product SKU</h1>
<form class="example-form" [formGroup]="formGroup" (submit)="submit()">
  <div mat-dialog-content>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Product</mat-label>
      <input type="text"
             placeholder="Search"
             aria-label="Product"
             matInput
             required
             [formControlName]="'product'"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let productSku of productOptions$ | async"
          [value]="productSku"
          (onSelectionChange)="select($event)"
        >
          {{productSku.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="float-right">
    <button mat-stroked-button color="warn" type="button" (click)="close()">Cancel</button>
    <button mat-button color="primary" type="submit" [disabled]="!formGroup.valid">Add</button>
  </div>
</form>
