import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatCustomDataSource } from '@ov-suite/ui';
import { ColumnData, doubleToRandString } from '@ov-suite/helpers-shared';
import { OrderItemModel, OrderModel, ReturnItem } from '@ov-suite/models-order';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CustomerModel } from '@ov-suite/models-admin';
import { OrderService } from '../../../services/order/order.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ProductSkuDisplay, ProductSkuService } from '../../../services/order/product-sku.service';

@Component({
  selector: 'ov-suite-product-category-list',
  templateUrl: './product-category-list.component.html',
  styleUrls: ['./product-category-list.component.css']
})
export class ProductCategoryListComponent implements OnInit {
  @Input() customerChangeSubject: BehaviorSubject<number>;

  @ViewChild(MatSort) sort: MatSort;

  orderItemsMap = new Map<number, OrderItemModel>();

  splitOrderItemMap = new Map<number, OrderItemModel>();

  search: string;

  dataSource: MatCustomDataSource<ProductSkuDisplay>;

  orderItemMapSubject: BehaviorSubject<Map<number, OrderItemModel>[]> = new BehaviorSubject<Map<number, OrderItemModel>[]>([new Map<number, OrderItemModel>(), new Map<number, OrderItemModel>()]);

  originalData: ProductSkuDisplay[];

  orderId: number;

  order: OrderModel;

  updating = false;

  orderItem: OrderItemModel;

  orderItems: OrderItemModel[];

  selectedCustomerId: number;

  items: ProductSkuDisplay[];

  selectedCustomer: CustomerModel;

  columnData: ColumnData<ProductSkuDisplay>[] = [
    {
      key: 'category.name',
      title: 'Category Name',
      type: 'deep-string',
    },
    {
      key: 'name',
      title: 'Product Name',
      type: 'deep-string',
    },
    {
      key: 'sku',
      title: 'Sku',
      type: 'deep-string',
    },
    {
      key: 'quantityOnHand',
      title: 'Available',
      type: 'number',
    },
    {
      key: 'quantity',
      title: 'Quantity',
      type: 'number',
      editable: true,
    },
    {
      keys: ['exclPrice'],
      title: 'Unit Price',
      type: 'other',
      action: item => {
        return isNaN(item.exclPrice) ? 'No pricing configured' : doubleToRandString(item.exclPrice);
      },
    },
    {
      keys: ['exclPrice', 'quantity'],
      title: 'Total',
      type: 'other',
      action: item => {
        const total = item.exclPrice * item.quantity;
        return total !== 0 && !isNaN(total) ? `${doubleToRandString(total)}` : 'R 0.00';
      },
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private readonly productSkuService: ProductSkuService<ReturnItem | OrderItemModel>,
    private readonly orderService: OrderService
  ) {
    this.dataSource = new MatCustomDataSource<ProductSkuDisplay>([]);
    this.orderItems = [];
  }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      if (params['id']) {
        this.orderId = params['id'];
        await this.fetchOrder(parseInt(this.orderId.toString(), 10));
        this.selectedCustomerId = this.order.customer.id;
        this.fetchCustomerData(this.selectedCustomerId).then(async () => {
          await this.fetchProductSkus();
          this.prepareTableData();
        })
      } else {
        await this.fetchProductSkus();
        this.customerChangeSubject.subscribe((customerId: number) => {
          if (customerId) {
            this.fetchCustomerData(customerId).then(async () => {
              this.selectedCustomerId = customerId;
              await this.fetchProductSkus();
              this.resetOrderItemPricing();
              this.prepareTableData();
            });
          }
        });
      }
    });
    this.setSorting();
  }

  ngOnDestroy(): void {
    this.customerChangeSubject.unsubscribe();
  }

  setSorting(): void {
    this.dataSource.sortData = (items, matSort) => {
      if (matSort && matSort.active && matSort.direction) {
        return this.sortOrderItems(items, matSort);
      }
      return items;
    };
  }

  sortOrderItems(items: ProductSkuDisplay[], sort?: MatSort) {
    if (sort && sort.direction) {
      const up = sort.direction === 'asc' ? 1 : -1;
      switch (sort.active) {
        case 'Category Name':
          items.sort((a, b) => (a.category.name.toLowerCase() > b.category.name.toLowerCase() ? up : -up));
          break;
        case 'Product Name':
          items.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? up : -up));
          break;
        case 'SKU':
          items.sort((a, b) => (a.sku.toLowerCase() > b.sku.toLowerCase() ? up : -up));
          break;
        case 'Quantity':
          items.sort((a, b) => (a.quantity > b.quantity) ? up : -up);
          break;
        case 'Unit Price':
          items.sort((a, b) => (a.exclPrice > b.exclPrice ? up : -up));
          break;
        case 'Total':
          items.sort((a, b) => ((a.quantity * a.exclPrice) > (b.quantity * b.exclPrice) ? up : -up));
          break;
        default:
      }
    }

    return items;
  }

  async fetchOrder(id: number): Promise<OrderModel> {
    this.order = await this.orderService.loadOrder(id);
    this.initializeOrderItems();
    return this.order;
  }

  /**
   * Initializes the quantities selected for products when an order is placed.
   */
  initializeOrderItems() {
    const orderedItems = new Map();
    const splitOrderedItems = new Map();

    this.order.orderItems.forEach(item => {
      if (item.split) {
        splitOrderedItems.set(item.productSkuId, item);
      } else {
        orderedItems.set(item.productSkuId, item);
      }
    });

    // At the point of generating display data, add split and normal ordered items
    Array.from(orderedItems.keys()).forEach(key_productSkuId => {
      if (splitOrderedItems.has(key_productSkuId)) {
        const orderItemToAdjust = orderedItems.get(key_productSkuId);
        orderItemToAdjust.quantity += splitOrderedItems.get(key_productSkuId).quantity;
      }
    })

    this.orderItemsMap = orderedItems;
    this.splitOrderItemMap = splitOrderedItems;

    this.orderItemMapSubject.next([this.orderItemsMap, splitOrderedItems]);
  }

  async fetchCustomerData(id: number): Promise<CustomerModel> {
    this.selectedCustomer = await this.productSkuService.getSelectedCustomer(id);
    return this.selectedCustomer;
  }

  async fetchProductSkus(): Promise<void> {
    this.originalData = await this.productSkuService.listCategoryProducts(this.selectedCustomer);
    this.dataSource.data = this.originalData;
  }

  resetOrderItemPricing() {
    const orderItemMapKeys = Array.from(this.orderItemsMap.keys());
    const configuredProducts = this.originalData.filter(data => orderItemMapKeys.includes(data.id));

    configuredProducts.forEach(product => {
      // Checking normal order items
      if (product.exclPrice && product.inclPrice) {
        let tempQuantity = 0;

        if (this.splitOrderItemMap.has(product.id)) {
          tempQuantity = this.splitOrderItemMap.get(product.id).quantity;
        }

        if (this.orderItemsMap.has(product.id)) {
          const configuredProduct = this.orderItemsMap.get(product.id);

          configuredProduct.quantity += tempQuantity;

          console.log(configuredProduct.quantity, tempQuantity)

          configuredProduct.unitPriceExcl = product.exclPrice;
          configuredProduct.unitPriceIncl = product.inclPrice;
        }
      } else {
        this.orderItemsMap.delete(product.id);
        this.splitOrderItemMap.delete(product.id);
      }
    })

    this.orderItemMapSubject.next([this.orderItemsMap, this.splitOrderItemMap]);
  }

  onSearchChange(input: string) {
    if (input) {
      const loweredInput = input.toLowerCase();
      const originalDataFiltered = this.originalData;

      this.dataSource.data = originalDataFiltered.filter(item => {
        return item.sku.toLowerCase().includes(loweredInput) ||
          item.name.toLowerCase().includes(loweredInput) ||
          item.category.name.toLowerCase().includes(loweredInput);
      });
    } else {
      this.dataSource.data = this.originalData;
    }
  }

  prepareTableData() {
    let displayData = this.originalData;

    displayData.forEach(dd => {
      if (this.orderItemsMap.has(dd.id)) {
        if (dd.inclPrice && dd.exclPrice) {
          dd.quantity = this.orderItemsMap.get(dd.id).quantity;
        }
      }
    })

    this.dataSource.data = displayData;
  }

  initOrderItem(skuInfo: ProductSkuDisplay) {
    let orderItem = new OrderItemModel();

    orderItem.productSkuId = skuInfo.id;
    orderItem.unitPriceExcl = skuInfo.exclPrice;
    orderItem.unitPriceIncl = skuInfo.inclPrice;
    orderItem.quantity = skuInfo.quantity;
    orderItem.productSku = skuInfo.model;

    return orderItem;
  }

  updateOrderItem(item: ProductSkuDisplay) {
    if (!item.inclPrice || !item.exclPrice) {
      this.showSnackBar('Product pricing not configured. Ignored.');
      return;
    }

    if (item.quantity < 0) {
      this.showSnackBar('Quantity can not be less than 0');
      return;
    }

    if (this.orderItemsMap.has(item.id)) {
      let orderItem = this.orderItemsMap.get(item.id);
      orderItem.quantity = item.quantity;
    } else {
      this.orderItemsMap.set(item.id, this.initOrderItem(item))
    }

    this.orderItemMapSubject.next([this.orderItemsMap, this.splitOrderItemMap]);
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
