<div class="main-content">
  <div class="main-container px-4 py-3">
    <span id="backButton" (click)="navigateBack()">
        </span>
    <div class="header"><i (click)="close()" aria-hidden="true" class="arrow-left-dark pull-left cursor-pointer"></i>
      <span>&nbsp;Confirmation</span></div>
    <p>{{message}}</p>
    <div>
      <button class="btn btn-outline-orderlink float-left" (click)="close()">
        <span class="float-left">
          Cancel
        </span>
        <span class="float-right">
          <i aria-hidden="true" class="x-dark"></i>
        </span>
      </button>
      <button [disabled]="this.checkingCreditLimit" class="btn btn-ovsuite-primary float-right" (click)="confirm()">
        <span class="float-left">
          Confirm
        </span>
        <span *ngIf="this.checkingCreditLimit" class="spinner-border spinner-border-sm accordion-spinner float-right" role="status" aria-hidden="true"></span>
        <span *ngIf="!this.checkingCreditLimit" class="float-right">
          <i aria-hidden="true" class="check-1"></i>
        </span>
      </button>
    </div>
  </div>
</div>
