import { NgModule } from '@angular/core';
import { UiModule } from '@ov-suite/ui';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { INVENTORYROUTES } from './inventory.routing';
import { InventoryListComponent } from './list-inventory/inventory-list.component';
import { InventoryAddEditComponent } from './add-edit/add-edit-inventory.component';
import { CustomerDetailsBarModule } from '../customer-details-bar/customer-details-bar.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(INVENTORYROUTES),
    FormsModule,
    UiModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    CustomerDetailsBarModule,
  ],
  declarations: [InventoryListComponent, InventoryAddEditComponent],
})
export class InventoryModule {}
