import { NgModule } from '@angular/core';
import { UiModule } from '@ov-suite/ui';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { OrdersListComponent } from './list-orders/orders-list.component';
import { OrdersRoutes } from './orders.routing';
import { OrderDetailsModalComponent } from './modals/order-details-modal/order-details-modal.component';
import { AllocateSalesRepModalComponent } from './modals/allocate-sales-rep-modal/allocate-sales-rep-modal.component';
import { OrderAddEditComponent } from './add-edit/add-edit-order.component';
import { CustomerDetailsBarModule } from '../customer-details-bar/customer-details-bar.module';
import { CommentModalModule } from './modals/comment-modal/comment-modal.module';
import { ScheduleOrderModalComponent } from './modals/schedule-order-modal/schedule-order-modal.component';
import { DeliveryDetailsComponent } from './delivery-details/delivery-details.component';
import { OverrideModalComponent } from './modals/override-modal/override-modal.component';
import { SplitDetailsListComponent } from './list-split-details/split-details-list.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { CustomerDetailsEditModule } from '../customer-details-edit/customer-details-edit.module';
import { OrderItemsDetailsModalComponent } from './modals/order-items-details-modal/order-items-details-modal.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { IntegrationExceptionDialogComponent } from './modals/integration-exception-dialog/integration-exception-dialog.component';
import { ProductCategoryListComponent } from './product-category-list/product-category-list.component';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { ProductSkuService } from "../../services/order/product-sku.service";
import { SplitOrderItemQuantityComponent } from './split-order-item-quantity/split-order-item-quantity.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(OrdersRoutes),
    FormsModule,
    UiModule,
    MatSnackBarModule,
    MatSortModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    CustomerDetailsBarModule,
    CommentModalModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTableModule,
    CustomerDetailsEditModule,
    MatCheckboxModule
  ],
  declarations: [
    OrdersListComponent,
    OrderDetailsModalComponent,
    DeliveryDetailsComponent,
    AllocateSalesRepModalComponent,
    ScheduleOrderModalComponent,
    OrderAddEditComponent,
    SplitDetailsListComponent,
    OverrideModalComponent,
    OrderItemsDetailsModalComponent,
    OrderSummaryComponent,
    IntegrationExceptionDialogComponent,
    ProductCategoryListComponent,
    SplitOrderItemQuantityComponent,
  ],
  providers: [
    MatDatepickerModule,
    ProductSkuService
  ]
})
export class OrdersModule {}
