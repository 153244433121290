<div>
  <div class="top-part">
    <label class="order-label">Order {{order.orderCode}} Comments</label>
  </div>
    <div class="middle-part" #scroll>
      <span *ngIf="this.loading" class="spinner-border spinner-border-sm spinner" role="status" aria-hidden="true"></span>
      <div *ngFor="let comment of comments; let i = index" class="comments" >
        <ng-container *ngIf="!comment.target">

          <div class="comment" [class.highlight]="i === highlight">
            <div class="photo">
              <img *ngIf="!comment.user.avatarUrl" src="../../../../../assets/img/placeholder.jpg" alt="avatar" />
              <img *ngIf="!!comment.user.avatarUrl" [src]="comment.user.avatarUrl" alt="avatar" />
            </div>
            <div class="comment-header">

              <div class="user">{{comment.user.firstName}} {{comment.user.lastName}}
                <span [ngClass]="i === highlight  ? 'cancel-button' : 'reply-button'" (click)="i === highlight ? cancel() : reply(comment, i)">
                  <i [ngClass]="i === highlight  ? 'fa fa-times' : 'fa fa-reply'" aria-hidden="true"></i>
                </span>
              </div>
              <div class="timestamp">{{getPrettyDate(comment.timestamp)}}</div>

            </div>
            <div class="message">{{comment.message}}</div>
          </div>

          <div *ngFor="let reply of comment.replies" class="comments reply" >
            <div class="photo">
              <img *ngIf="!reply.user.avatarUrl" src="../../../../../assets/img/placeholder.jpg" alt="avatar" />
              <img *ngIf="!!reply.user.avatarUrl" [src]="reply.user.avatarUrl" alt="avatar" />
            </div>
            <div class="comment-header">
              <div class="user">{{reply.user.firstName}} {{reply.user.lastName}}</div>
              <div class="timestamp">{{getPrettyDate(reply.timestamp)}}</div>
            </div>
            <div class="message">{{reply.message}}</div>
          </div>

        </ng-container>
      </div>
      <span *ngIf="comments.length === 0 && !loading" class="no-comments">No comments...</span>
    </div>
  <div class="reply-box">
    <label *ngIf="target" >Replying to: <strong>{{target?.user?.firstName}} {{target?.user?.lastName}}</strong></label>
  </div>
    <div class="bottom-part">
          <ov-suite-input
            class="comment-input"
            [id]="'commentInput'"
            [name]="'comment'"
            [type]="'text'"
            [inputLabel]="''"
            [(ngModel)]="comment"
            [placeholder]="''"
            [danger]="danger"
            (ngModelChange)="commentChanged()"
            (keyup.enter)="saveComment(target)"
          >
          </ov-suite-input>
        <button class="btn-ovsuite-primary add-comment" (click)="saveComment(target)" [disabled]="inputDisabled"><i aria-hidden="true" class="arrow-right"></i></button>
    </div>
  </div>
