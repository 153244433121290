<div class="main-content">
  <div class="main-container">
    <ov-suite-hierarchy-breadcrumb
      [ovAutoService]="ovAutoService"
      [formClass]="entity"
    ></ov-suite-hierarchy-breadcrumb>
    <ov-suite-hierarchy-table
      [title]="'Product List'"
      [formClass]="formClass"
      [overrideColumnData]="columns"
      [filter]="filter"
      [filterEnabled]="true"
      [hasBulkUpload]="false"
      [showTopBar]="true"
      [editableRows]="true"
      [hideAddButton]="true"
      [dataOverride]="currentData"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
