
<div class="form-group">
  <div class="input-parent" [ngClass]="{'not-valid-input': !quantities?.valid}">
    <div class="input-row">


      <div class="option start">
        <span disabled="true"
              *ngIf="quantities?.valid"
              [ngClass]="{'low-limit': quantities?.current === 0 || decrementDisabled}"
              class="picker-btn"
              (click)="getQuantities(quantities.low, sku, false,false, decrementDisabled)" >
          <i aria-hidden="true" class="fa fa-minus picker-icon"></i>
        </span>
        <div *ngIf="!quantities?.valid" class="picker-value" (click)="getQuantities(quantities.low, sku, false,false, decrementDisabled)">{{quantities?.low}}</div>
      </div>

      <div class="option current">
        <input type="text" [ngClass]="{'not-valid-option': !quantities?.valid}" [(ngModel)]="currentValue" />
      </div>

      <div class="option end">
        <span *ngIf="quantities?.valid"
              [ngClass]="{'low-limit': incrementDisabled}"
              class="picker-btn"
              (click)="getQuantities(quantities.high, sku, false,true, incrementDisabled)" >
          <i aria-hidden="true" class="fa fa-plus picker-icon"></i>
        </span>
        <div *ngIf="!quantities?.valid" class="picker-value" (click)="getQuantities(quantities.high, sku, false,true, incrementDisabled)">{{quantities?.high}}</div>
      </div>


    </div>
  </div>
</div>
