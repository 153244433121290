<mat-card class="mat-elevation-z0">
  <mat-card-content class="input-area">
    <mat-form-field appearance="outline">
      <mat-label>Search Order Items</mat-label>
      <input matInput
             type="text"
             [(ngModel)]="search"
             (ngModelChange)="onSearchChange($event)"
             placeholder="Search Order Items"
      >
    </mat-form-field>
  </mat-card-content>
  <mat-card-content>
    <ov-suite-clean-table
      [columnData]="columnData"
      [dataSource]="dataSource"
      emptyMessage="No Order Items"
      [overrideUpdate]="true"
      (updateEventEmitter)="updateOrderItem($event)"
    >
    </ov-suite-clean-table>
  </mat-card-content>
  <mat-card-actions class="order-items-dialog float-right">
    <button mat-button
            color="primary"
            (click)="closeDialog()"
    >
      Okay
    </button>
  </mat-card-actions>
</mat-card>
