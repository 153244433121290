import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FilterTab } from '@ov-suite/ui';
import { OrderModel } from '@ov-suite/models-order';
import { ColumnData, OrderPriorityConstant, QueryParams, RowData } from '@ov-suite/helpers-shared';
import { Router } from '@angular/router';
import { AllocateSalesRepModalComponent } from '../modals/allocate-sales-rep-modal/allocate-sales-rep-modal.component';
import { CommentModalComponent } from '../modals/comment-modal/comment-modal.component';
import { ScheduleOrderModalComponent } from '../modals/schedule-order-modal/schedule-order-modal.component';
import { ConfirmationDialogData, ConfirmationDialogService } from '@ov-suite/services';
import swal from 'sweetalert2';
import { OrderService } from '../../../services/order/order.service';

@Component({
  selector: 'ov-suite-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: [ './orders-list.component.scss' ],
})
export class OrdersListComponent {
  @ViewChild('orderItemsDialog') orderDetailsModal: TemplateRef<unknown>;

  @ViewChild('integrationExceptionAction') integrationExceptionAction: TemplateRef<unknown>;

  selectedOrder: OrderModel;

  selectedIntegrationOrder: OrderModel;

  formClass = OrderModel;

  rows: RowData<OrderModel> = {
    style: order => {
      return this.getRowStyle(order);
    },
    keys: [ 'customer.creditLimit', 'approvalDate' ],
    type: 'rowStyle',
  };

  columns: ColumnData<OrderModel>[] = [
    {
      keys: [ 'id' ],
      title: 'Order ID',
      type: 'other',
      disableFiltering: true,
      action: order => order.orderCode,
      rowItemAction: (item) => {
        this.selectedOrder = item;
        this.openOrderItemsDialog(item);
      },
    },
    {
      key: 'orderDate',
      title: 'Order Date',
      type: 'date',
    },
    {
      key: 'orderCode',
      title: 'Order Code',
      hideColumnKey: 'orderCode',
      type: 'string',
    },
    {
      title: 'Customer Code',
      type: 'deep-string',
      key: 'customer.customerCode',
      disableSorting: true,
    },
    {
      title: 'Customer Name',
      type: 'deep-string',
      key: 'customer.name',
      disableSorting: true,
    },
    {
      title: 'Sales Rep',
      type: 'other',
      keys: ['salesRep.user.firstName', 'salesRep.user.lastName'],
      action: order => order.salesRep ? `${order?.salesRep?.user?.firstName} ${order?.salesRep?.user?.lastName}` : ' - ',
      disableSorting: true,
      disableFiltering: true,
      hideColumnKey: 'salesRep'
    },
    {
      title: 'Priority',
      filterKey: 'priority',
      disableFiltering: true,
      keys: ['priority'], // using orderItems for the popup
      type: 'other',
      action: order => {
        if (order.priority) {
          return `<span class="badge ${OrderPriorityConstant[ order?.priority ]?.color}">
            ${OrderPriorityConstant[ order?.priority ]?.text} </span>`;
        }
        return `<span class="badge badge-success">Not Specified</span>`;
      },
    },
    {
      key: 'fulfilmentDate',
      title: 'Shipping Date',
      type: 'date',
    },
    {
      keys: [ 'syncDate', 'integrationException', 'integrationId' ],
      title: 'Synced',
      type: 'other',
      action: order => {
        // Return your desired output here.
        if (order.integrationException || order.syncDate) {
          return `<span style="cursor: pointer;" class="badge badge-danger">No</span>`;
        } else if (order.integrationId) {
          return `<span style="cursor: pointer;" class="badge badge-success">Yes</span>`
        }
      },
      rowItemAction: (item) => {
        this.selectedIntegrationOrder = item;
        this.openIntegrationExceptionDialog(item);
      },
      disableFiltering: true,
      disableSorting: true,
    },
    {
      title: 'Actions',
      type: 'action-dropdown',
      disableSorting: true,
      disableFiltering: true,
      keys: ['customer.id', 'orderItems', 'orderItems.id', 'orderItems.unitPriceExcl', 'orderItems.quantity'],
      buttons: [
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'edit-2',
          title:'Edit',
          action: async item => {
            await this.router.navigate(['orders/edit', item.id]);
          },
        },
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'user-1',
          title:'Allocate Sales Rep',
          action: (item, { ngbModal }) => {
            const modalRef = ngbModal.open(AllocateSalesRepModalComponent, { size: 'md' });
            modalRef.componentInstance.order = item;
          },
        },
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'message-circle-1',
          title:'Comment',
          action: async (item, { ngbModal }) => {
            const modalRef = ngbModal.open(CommentModalComponent, { size: 'md', centered: true });
            modalRef.componentInstance.order = item;
          },
        },
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'fa fa-eject',
          title:'Release',
          action: (item, { ngbModal }) => {
            const modalRef = ngbModal.open(ScheduleOrderModalComponent, { size: 'md' });
            modalRef.componentInstance.order = item;
          },
        },
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'split',
          title:'Split',
          action: async item => {
            await this.router.navigate(['orders/split', item.id]);
          },
        },
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'fa fa-trash',
          title:'Delete',
          actionType: 'delete',
          action: (item, { columnAction }) => columnAction(),
        },
      ],
    },
  ];

  filter: Record<string, QueryParams[]> = null;

  selected: OrderModel[];

  tabs: FilterTab[] = [
    { title: 'All', value: null },
    { title: 'Unconfirmed', value: 'AWAITING_CONFIRMATION' },
    { title: 'Confirmed', value: 'CONFIRMED' },
    { title: 'Processing', value: 'PROCESSING' },
    { title: 'Completed', value: 'COMPLETED' },
    { title: 'Partially Processed', value: 'PARTIALLY_PROCESSED' },
  ];

  constructor(private readonly router: Router, private readonly confirmationDialogService: ConfirmationDialogService, private readonly orderService: OrderService) {}

  openOrderItemsDialog(order: OrderModel) {
    const dialogData: ConfirmationDialogData = {
      title: `Order ID ${order.orderCode}`,
      component: this.orderDetailsModal,
      overrideActions: true,
      preventClose: true,
      width: 850,
    };

    this.confirmationDialogService.openComponentDialog(dialogData);
  }

  async closeOrderItemsDialog(alteredOrder: boolean) {
    if (alteredOrder) {
      await this.orderService.resetOrder(this.selectedOrder);
    }

    this.confirmationDialogService.forceClose();
  }

  openIntegrationExceptionDialog(order: OrderModel) {
    const dialogData: ConfirmationDialogData = {
      title: `Order ID ${order.orderCode}`,
      component: this.integrationExceptionAction,
      width: 650,
    };

    this.confirmationDialogService.openComponentDialog(dialogData);
  }

  closeDialog(close: boolean) {
    if (close) {
      this.confirmationDialogService.forceClose();

      swal.fire({ title: 'Order successfully reset!', type: 'success' })
    }
  }

  setFilter(tab: FilterTab) {
    this.filter = tab.value ? { status: [tab.value] } : null;
  }

  getRowStyle(order: OrderModel): Object {
    const orderTotal = order.orderItems?.reduce((p, c) => p + c.unitPriceExcl, 0);
    if (order.customer?.creditLimit && ((orderTotal + order.customer?.accountBalance) > order.customer?.creditLimit)) {
      return { backgroundColor: '#FDF2F2', color: '#BBBEC5' };
    }
    if (order.approvalDate) {
      return { backgroundColor: '#EFFFF6' };
    }
    return null;
  }
}
