import { Component } from '@angular/core';
import { ColumnData } from '@ov-suite/helpers-shared';
import { SalesConstraintModel } from '@ov-suite/models-admin';

@Component({
  selector: 'ov-suite-sales-constraint-list',
  templateUrl: './sales-constraint-list.component.html',
})
export class SalesConstraintListComponent {
  columnData: ColumnData<SalesConstraintModel>[] = [
    {
      type: 'string',
      key: 'name',
      title: 'Name',
    },
    {
      title: 'Fast Actions',
      type: 'buttons',
      disableSorting: true,
      buttons: [
        {
          actionType: 'routeLink',
          classes: 'btn-sm',
          title: 'View',
          queryParams: data => ({ id: data.id }),
          routerLink: () => ['sales-constraint/edit'],
        },
        {
          actionType: 'delete'
        }
      ],
      keys: [],
    },
  ];

  formClass = SalesConstraintModel;
}

