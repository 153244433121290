import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductCategory, ProductSkuModel } from '@ov-suite/models-admin';
import { OrderItemModel, Reason, ReturnItem } from '@ov-suite/models-order';
import { InventoryAccordionService, MappedProducts, ProductSkuDisplay } from '@ov-suite/services';
import { orderTableHeader, returnTableHeader } from './product-accordion.constants';
import { Quantities } from './quantity-picker/constraints.helper';
import { BehaviorSubject } from 'rxjs';

interface ValidEmit {
  valid: boolean;
  productSkuId: number;
}

@Component({
  selector: 'ov-suite-accordion-category',
  templateUrl: './accordion-category.component.html',
  styleUrls: ['./accordion-category.component.scss'],
})
export class AccordionCategoryComponent<T extends OrderItemModel | ReturnItem> implements AfterViewInit {
  @Input() productCategories: MappedProducts[];

  @Input() customerChangeSubject: BehaviorSubject<boolean>;

  @Input() productListSubject: BehaviorSubject<Map<number, T>>;

  @Input() validChangeSubject: BehaviorSubject<{ productSkuId: number, valid: boolean }>;

  productItemMap_: Map<number, T>;

  @Input() set productItemMap(map: Map<number, T>) {
    this.productItemMap_ = map;
  }

  get productItemMap(): Map<number, T> {
    return this.productItemMap_;
  }

  @Input() type: string;

  returnReasons: Reason[];

  displayedColumns = [];

  selectedCategory: MappedProducts;

  @Input() selectedProductSkuList: ProductSkuModel[];

  constructor(public inventoryAccordionService: InventoryAccordionService<T>) {}

  async onCategorySelected(category: MappedProducts): Promise<void> {
    this.selectedCategory = null;

    category.categories.forEach(cat => {
      if (!cat.categories.length) {
        this.inventoryAccordionService.listProductCategories(cat.category.id).then(item => cat.categories.push(...item));
      }
    });

    const categorySkus: ProductSkuDisplay[] = await this.inventoryAccordionService.listCategoryProducts(category.category.id, category.category.path);
    category.skus = this.inventoryAccordionService.categorySkusWithDiscounts([...categorySkus], this.productItemMap);

    this.selectedCategory = category;
  }

  async ngAfterViewInit() {
    if (this.type === 'order') {
      this.displayedColumns = orderTableHeader;
    } else {
      this.displayedColumns = returnTableHeader;
      this.returnReasons = await this.inventoryAccordionService.getReasons();
    }

    this.customerChangeSubject.subscribe(async changed => {
      console.log(this.customerChangeSubject);
      if (changed && this.selectedCategory) {
        this.selectedCategory.skus = await this.inventoryAccordionService.listCategoryProducts(this.selectedCategory.category.id, this.selectedCategory.category.path);
      }
    })
  }

  getProductItemTotal(product: ProductSkuDisplay): string {
    if (!this.productItemMap.has(product.id)) {
      return '0';
    }

    const itemTotal = this.productItemMap.get(product.id).quantity * product.exclPrice;

    return itemTotal.toFixed(2);
  }

  // The product will hold all of its own information. So the logic can just be for managing individual products at a time.
  quantityChange(product: ProductSkuDisplay, quantities: Quantities) {
    if (quantities.current < 0) {
      return;
    }

    if (this.productItemMap.has(product.id)) {
      this.manageProducts(product, quantities.current, this.productItemMap.get(product.id), quantities.valid);
    } else {
      this.manageProducts(product, quantities.current, this.createProductListing(), quantities.valid);
    }
  }

  getProductModel(product: string) {
    return product as unknown as ProductSkuModel;
  }

  createProductListing(): T {
    if (this.type === 'order') {
      return new OrderItemModel() as T;
    }

    return new ReturnItem() as T;
  }

  formatPrice(price: number): string {
    return price.toFixed(2);
  }

  /**
   * Called when product quantity is changed. Emit values to the calling method so that it can be stored in the database.
   *
   * @param product
   * @param amount
   * @param selectedProduct
   * @param valid
   */
  manageProducts(product: ProductSkuDisplay, amount: number, selectedProduct: T, valid: boolean) {
    this.productItemMap.set(product.id, this.inventoryAccordionService[`${this.type}AdjustQuantity`](product, amount, selectedProduct));

    this.productListSubject.next(this.productItemMap);
    this.validChangeSubject.next({ valid, productSkuId: product.id })
  }

  reasonChange(product, id: number) {
    if (!this.productItemMap.has(product.id)) {
      return;
    }

    const item = this.productItemMap.get(product.id) as ReturnItem;

    item.reason = this.returnReasons.find(reason => reason.id === id);

    item.reasonId = id;

    this.productItemMap.set(product.id, item as T);

    this.productListSubject.next(this.productItemMap);
  }

  /**
   * Finds the total value of all products ordered within categories. This calculation also works on all sub categories
   * within categories
   * @param category
   */
  getCategoryTotals(category: ProductCategory): number | string {
    const total = [...this.productItemMap.values()].reduce((p, c) => {
      if (c.productSku.category.path.startsWith(category.path)) {
        return p + (c.unitPriceExcl * c.quantity);
      }
      return p;
    }, 0);

    return total > 0 ? `R ${(Math.round(total * 100) / 100).toFixed(2)}` : '';
  }

  /**
   * Calculates the total quantity of products ordered. Works on sub categories within categories as well.
   * @param category
   */
  getQuantityTotals(category: ProductCategory): number | string {
    const quantity = [...this.productItemMap.values()].reduce((p, c) => {
      if (c.productSku.category.path.startsWith(category.path)) {
        return p + c.quantity;
      }
      return p;
    }, 0);

    return quantity > 0 ? `Quantity ${quantity}` : '';
  }
}
