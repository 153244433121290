import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OrderItemModel } from '@ov-suite/models-order';
import { BehaviorSubject } from 'rxjs';
import { ColumnData, doubleToRandString } from '@ov-suite/helpers-shared';
import { MatCustomDataSource } from '@ov-suite/ui';
import { ConfirmationDialogData, ConfirmationDialogService } from '@ov-suite/services';
import _ from 'lodash';

@Component({
  selector: 'ov-suite-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})


export class OrderSummaryComponent implements OnInit {
  @Input() orderItemMapSubject: BehaviorSubject<Map<number, OrderItemModel>[]>;

  @Input() splitOrderItemMap: Map<number, OrderItemModel>;

  dataSource: MatCustomDataSource<OrderItemModel> = new MatCustomDataSource<OrderItemModel>([]);

  selectedOrderItem: OrderItemModel;

  originalSelectedOrderItem: OrderItemModel;

  // Hold temporary operations and only used when modal confirm clicked.
  tempSplitOrderItem: OrderItemModel;

  @ViewChild('splitQuantity') splitQuantity: TemplateRef<any>;

  constructor(private readonly dialogService: ConfirmationDialogService) { }

  columnData: ColumnData<OrderItemModel>[] = [
    {
      key: 'productSku.name',
      title: 'Product Name',
      type: 'deep-string',
    },
    {
      key: 'productSku.sku',
      title: 'SKU',
      type: 'deep-string',
    },
    {
      key: 'quantity',
      title: 'Quantity',
      type: 'number',
      editable: false,
    },
    {
      key: 'split',
      title: 'Split',
      type: 'boolean',
      editable: false,
    },
    {
      keys: [ 'unitPriceExcl' ],
      title: 'Unit Price',
      type: 'other',
      action: item => `${doubleToRandString(item.unitPriceExcl)} `,
    },
    {
      keys: ['unitPriceExcl','quantity'],
      title: 'Total',
      type: 'other',
      action: item => {
        return `${doubleToRandString(item.unitPriceExcl * item.quantity)} `;
      },
    },
    {
      title: 'Actions',
      type: 'button-bar',
      disableSorting: true,
      keys: [],
      buttons: [
        {
          classes: 'btn btn-dark btn-sm mx-1',
          icon: 'fa fa-file-text-o',
          actionType: 'custom',
          action: orderItem => {
            if (this.splitOrderItemMap.has(orderItem.productSkuId)) {
              this.selectedOrderItem = this.splitOrderItemMap.get(orderItem.productSkuId);
            } else {
              this.selectedOrderItem = orderItem;
            }

            this.originalSelectedOrderItem = _.clone(this.selectedOrderItem);
            this.onSplitQuantity();
          }
        },
      ],
    }
  ];

  ngOnInit() {
    this.orderItemMapSubject.subscribe((itemMap) => {
      if (itemMap) {
        const [_normalItems, _splitItems] = itemMap;

        const normalItems = _.clone(_normalItems)
        const splitItems = _.clone(_splitItems)

        Array.from(normalItems.keys()).forEach(productSkuId => {
          if (splitItems.has(productSkuId)) {
            const normal = normalItems.get(productSkuId);
            const split = splitItems.get(productSkuId);

            normal.quantity -= split.quantity;
            normalItems.set(productSkuId, normal);
          }
        })

        this.prepareDataSourceData([...Array.from(normalItems.values()), ...Array.from(splitItems.values())]);
      }
    });
  }

  ngOnDestroy(): void {
    this.orderItemMapSubject.unsubscribe();
  }

  prepareDataSourceData(itemMap: OrderItemModel[]) {
    this.dataSource.data = Array.from(itemMap.values()).filter(item => item.quantity > 0);
  }

  onSplitQuantity() {
    // TODO if there is an update operation, ie, we find this product in the split items stuff, then push an existing item into the modal.

    const dialogData: ConfirmationDialogData = {
      title: `Split Product Quantity`,
      component: this.splitQuantity,
      width: 500,
      preventClose: true,
    }

    this.dialogService.openComponentDialog(dialogData).subscribe(output => {
      if (output) {
        this.splitOrderItemMap.set(this.tempSplitOrderItem.productSkuId, this.tempSplitOrderItem);
        const normalItems = _.clone(this.orderItemMapSubject.getValue()[0]);
        const splitItems = _.clone(this.splitOrderItemMap);

        Array.from(normalItems.keys()).forEach(productSkuId => {
          if (splitItems.has(productSkuId)) {
            const normal = normalItems.get(productSkuId);
            const split = splitItems.get(productSkuId);

            normal.quantity -= split.quantity;
            normalItems.set(productSkuId, normal);
          }
        })

        this.prepareDataSourceData([...Array.from(normalItems.values()), ...Array.from(splitItems.values())]);
      } else {
        this.selectedOrderItem.quantity = this.originalSelectedOrderItem.quantity;
      }
    })
  }

  splitOrderItemUpdate(updatedOrderItem: OrderItemModel) {
    this.tempSplitOrderItem = updatedOrderItem;
  }
}
