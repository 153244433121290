<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="summary-header">Order Summary</mat-panel-title>
    </mat-expansion-panel-header>
      <ov-suite-clean-table
        [columnData]="columnData"
        [dataSource]="dataSource"
        emptyMessage="No Order Items"
      >
      </ov-suite-clean-table>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #splitQuantity>
  <ov-suite-split-order-item-quantity
    [selectedOrderItem]="selectedOrderItem"
    (splitOrderItemOutput)="splitOrderItemUpdate($event)"
  >
  </ov-suite-split-order-item-quantity>
</ng-template>
