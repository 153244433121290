import { Injectable } from '@angular/core';
import { CustomerDeliveryAddress, CustomerModel } from '@ov-suite/models-admin';
import { OvAutoService } from '@ov-suite/services';
import { OrderItemModel, OrderModel, Reason, ReturnItem, ReturnModel, SalesRepModel } from '@ov-suite/models-order';
import { getCreate, getUpdate } from '@ov-suite/graphql-helpers';
import { firstValueFrom } from 'rxjs';
import { gql } from '@apollo/client';

@Injectable()
export class OrderService {
  returnReasons: Reason[]

  constructor(private readonly ovAutoService: OvAutoService) {}

  async loadOrder(id: number): Promise<OrderModel> {
    return this.ovAutoService.get({
      entity: OrderModel,
      id: id,
      keys: [
        'id',
        'orderDate',
        'orderCode',
        'priority',
        'externalOrderNo',
        'captureSupergroup',
        'orderItems.id',
        'orderItems.split',
        'orderItems.note',
        'orderItems.quantity',
        'orderItems.productSkuId',
        'orderItems.unitPriceExcl',
        'orderItems.unitPriceIncl',
        'orderItems.productSku.id',
        'orderItems.productSku.sku',
        'orderItems.productSku.name',
        'orderItems.productSku.category.id',
        'orderItems.productSku.category.path',
        'deliveryAddress',
        'customer.name',
        'customer.id',
        'salesRep.id',
        'salesRep.user.id',
        'salesRep.user.firstName',
        'salesRep.user.lastName',
      ]
    });
  }

  async listCustomersByName(search: string): Promise<CustomerModel[]> {
    return this.ovAutoService.list({
      entity: CustomerModel,
      orderColumn: 'name',
      search: { 'name': [ search ], 'customerCode': [ search ] },
      keys: [
        'id',
        'name',
        'customerCode',
        'defaultDeliveryAddress',
        'defaultPriceListId',
      ],
      limit: 10,
    }).then(res => res.data);
  }

  async listSalesRepByName(search: string): Promise<SalesRepModel[]> {
    return this.ovAutoService.list({
      entity: SalesRepModel,
      search: { 'user.firstName': [ search ], 'user.lastName': [ search ] },
      keys: [
        'id',
        'user.id',
        'user.firstName',
        'user.lastName'
      ],
      limit: 10,
    }).then(res => res.data);
  }

  async createOrder(order: OrderModel, orderItems: OrderItemModel[]): Promise<OrderModel> {
    order.syncDate = new Date().toISOString();

    const item = getCreate(order);
    delete item.isSplit;

    item.orderItems = [];

    orderItems.forEach(orderItem => {
      item.orderItems.push(getCreate(orderItem));
    });

    return this.ovAutoService.create({
      entity: OrderModel,
      item: item,
      refreshModel: false
    })
  }

  updateOrder(
    order: OrderModel, originalOrder: OrderModel,
    orderItems: Map<number, OrderItemModel>, originalOrderItems: Map<number, OrderItemModel>,
    splitOrderItems: Map<number, OrderItemModel>, originalSplitOrderItems: Map<number, OrderItemModel>
    ): Promise<OrderModel> {
    order.syncDate = new Date().toISOString();
    order.integrationException = null;

    const item = getUpdate(order, originalOrder);
    item.orderItems = [];
    delete item.isSplit;

    delete item['orderItemsIdList'];

    item.orderCode = order.orderCode;

    orderItems.forEach(orderItem => {
      let updatedOrderItem;

      if (originalOrderItems.has(orderItem.productSkuId)) {
        if (orderItem.quantity === 0) {
          this.ovAutoService.delete(OrderItemModel, orderItem.id);
          return;
        } else {
          updatedOrderItem = getUpdate(orderItem, originalOrderItems.get(orderItem.productSkuId));
        }

        delete updatedOrderItem.processedQuantity;
      } else {
        updatedOrderItem = getCreate(orderItem);
      }

      updatedOrderItem.orderId = order.id;
      item.orderItems.push(updatedOrderItem);
    });

    splitOrderItems.forEach(orderItem => {
      let updatedOrderItem;

      if (originalSplitOrderItems.has(orderItem.productSkuId)) {
        if (orderItem.quantity === 0) {
          this.ovAutoService.delete(OrderItemModel, orderItem.id);
          return;
        } else {
          updatedOrderItem = getUpdate(orderItem, originalSplitOrderItems.get(orderItem.productSkuId));
        }

        delete updatedOrderItem.processedQuantity;
      } else {
        updatedOrderItem = getCreate(orderItem);
      }

      updatedOrderItem.orderId = order.id;
      item.orderItems.push(updatedOrderItem);
    });

    return this.ovAutoService.update({
      entity: OrderModel,
      item: item,
      refreshModel: false
    })
  }

  // Specifically for returns
  async getReasons(): Promise<Reason[]> {
    this.returnReasons = await this.ovAutoService.list({
      entity: Reason,
      orderColumn: 'reason',
    }).then(res => res.data);

    return this.returnReasons;
  }

  async loadReturn(id: number): Promise<ReturnModel> {
    return this.ovAutoService.get({
      entity: ReturnModel,
      id: id,
      relations: ['customer', 'returnItems', 'salesRep']
    });
  }

  async createReturn(returnObject: ReturnModel, returnItems: Map<number, ReturnItem>): Promise<ReturnModel> {
    const item = getCreate(returnObject);

    item.returnItems = [];

    returnItems.forEach(returnItem => {
      const formatted = getCreate(returnItem);

      item.returnItems.push(formatted);
    })

    return this.ovAutoService.create({
      entity: ReturnModel,
      item,
    })
  }

  updateReturn(returnObject: ReturnModel, originalReturnObject: ReturnModel, returnItems: Map<number, ReturnItem>, originalReturnItems: Map<number, ReturnItem>): Promise<ReturnModel> {
    const item = getUpdate(returnObject, originalReturnObject);
    item.returnItems = [];

    delete item['returnItemsIdList'];

    returnItems.forEach(returnItem => {
      let updatedReturnItem;

      if (originalReturnItems.has(returnItem.productSkuId)) {

        if (returnItem.quantity === 0) {
          this.ovAutoService.delete(ReturnItem, returnItem.id);
          return;
        } else {
          updatedReturnItem = getUpdate(returnItem, originalReturnItems.get(returnItem.productSkuId));
        }

        delete updatedReturnItem.processedQuantity;
      } else {
        updatedReturnItem = getCreate(returnItem);
      }

      updatedReturnItem.returnId = returnObject.id;

      item.returnItems.push(updatedReturnItem);
    });

    return this.ovAutoService.update({
      entity: ReturnModel,
      item: item,
    })
  }

  async resetOrder(order: OrderModel) {
    const item = {
      id: order.id,
      integrationException: null,
      syncDate: new Date().toISOString(),
      orderCode: order.orderCode,
    }

    return firstValueFrom(
      this.ovAutoService.apollo.mutate({
        mutation: gql`
          mutation orderSyncReset($id: Int!, $order: OrderUpdateSimpleInput!) {
            orderSyncReset(id: $id, order: $order)
          }
        `,
        variables: {
          id: order.id,
          order: item,
        },
      }),
    ).then(res => res.data);
  }

  async fetchDeliveryAddresses(customerId: number) {
    return await this.ovAutoService.list({
      entity: CustomerDeliveryAddress,
      query: { 'customerId': [ customerId ] },
      limit: 1000,
    }).then(res => res.data);
  }
}
